import InputField from "core/components/formfields/InputField";
import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { fastFoodImg } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { formatCurrency, getDateTime } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";

const CreateServiceDirectory = () => {
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const user = useUserStore((store) => store.user);

  const directoryList = useMarketPlaceStore(
    (store) => store.serviceDirectoryList,
  );
  const getDirectoryServiceAction = useMarketPlaceStore(
    (store) => store.getDirectoryService,
  );

  const addUpdateServiceAction = useMarketPlaceStore(
    (store) => store.addUpdateDirectoryService,
  );

  // eslint-disable-next-line eqeqeq
  const currentLocation = locations?.find((x) => x.name == user?.location)?.id;

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedDirectory, setSelectedDirectory] = useState<any>(null);

  const [newDirectory, setNewDirectory] = useState<NewDirectoryService>({
    convenienceFee: 0,
    locationId: "",
    subscriptionFee: 0,
  });

  const onFormChange = (e: any, onChange: any) => {
    const { name, value } = e.target;

    onChange((state: any) => ({
      ...state,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState<any>({
    ConvenienceFee: [],
    SubscriptionFee: [],
  });

  const resetErrors = () => {
    setErrors({
      ConvenienceFee: [],
      SubscriptionFee: [],
    });
  };

  const validateDirectory = (directory: NewDirectoryService | any) => {
    var isValid = true;

    if (+directory?.subscriptionFee < 1) {
      isValid = false;

      setErrors((state: any) => ({
        ...state,
        SubscriptionFee: ["Subscription Fee must be greater than 0"],
      }));
    }

    return isValid;
  };

  const addDirectory = async (e: any) => {
    e.preventDefault();

    if (validateDirectory(newDirectory)) {
      var res = await addUpdateServiceAction(
        { ...newDirectory, locationId: currentLocation! },
        "",
      );

      if (res?.isSuccessful) {
        setNewDirectory({
          locationId: "",
          convenienceFee: 0,
          subscriptionFee: 0,
        });
      } else {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const updateDirectory = async (e: any) => {
    e.preventDefault();

    if (validateDirectory(selectedDirectory)) {
      var res = await addUpdateServiceAction(
        {
          ...selectedDirectory,
          locationId: currentLocation,
        },
        selectedDirectory?.id,
      );

      if (!res?.isSuccessful) {
        if (res?.data?.errors != null) {
          setErrors(res?.data?.errors);
        }
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const fetchMore = (page: number) => {
    getDirectoryServiceAction(page, directoryList?.pageSize);
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();

    getDirectoryServiceAction(
      directoryList?.pageNumber,
      directoryList?.pageSize,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="mb-[24px] flex items-center justify-between">
          <header className="flex items-center gap-3">
            <img src={fastFoodImg} alt="active module" loading="lazy" />
            <h5 className="text-[18px] font-[500] text-brand">
              Service Directory
            </h5>
          </header>
        </div>

        <main className="my-[28px] flex gap-5">
          <div className="w-4/12 rounded-[5px] bg-white p-5">
            <div className="mb-[28px] text-center">
              <p>Add Service Directory</p>
            </div>
            <form onSubmit={addDirectory}>
              <InputField
                boxStyle="mb-[24px]"
                label="Subscription Fee"
                placeholder="Subscription Fee"
                name="subscriptionFee"
                type="number"
                value={newDirectory?.subscriptionFee}
                onChange={(e: any) => onFormChange(e, setNewDirectory)}
                error={errors?.SubscriptionFee[0]}
                onBlur={() => {
                  setErrors((state: any) => ({
                    ...state,
                    SubscriptionFee: [],
                  }));
                }}
              />

              <InputField
                boxStyle="mb-[24px]"
                placeholder="Convenience Fee"
                label="Convenience Fee"
                name="convenienceFee"
                type="number"
                value={newDirectory?.convenienceFee}
                onChange={(e: any) => onFormChange(e, setNewDirectory)}
                error={errors?.ConvenienceFee[0]}
                onBlur={() => {
                  setErrors((state: any) => ({
                    ...state,
                    ConvenienceFee: [],
                  }));
                }}
              />
              <button className={`${btn} w-full bg-brand text-white`}>
                Submit
              </button>
            </form>
          </div>

          <div className="w-8/12">
            <Table
              headers={[
                "Location",
                "Subscription Fee",
                "Convenience Fee",
                "Updated At",
                "Created At",
                "Action",
              ]}
            >
              {directoryList?.serviceDirectories?.length > 0 ? (
                directoryList?.serviceDirectories?.map(
                  (service: DirectoryService) => (
                    <tr key={service?.id}>
                      <Row
                        value={
                          locations?.find((x) => x.id === service?.locationId)
                            ?.name
                        }
                      />
                      <Row value={formatCurrency(service?.subscriptionFee)} />
                      <Row value={formatCurrency(service?.convenienceFee)} />
                      <Row value={getDateTime(service?.updatedAt)} />
                      <Row value={getDateTime(service?.createdAt)} />
                      <ActionRow
                        style={`flex items-center justify-center gap-3`}
                      >
                        <button
                          className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                          onClick={() => {
                            setSelectedDirectory(service);
                            setShowUpdateModal(true);
                          }}
                          disabled={isSuperAdmin}
                        >
                          Update
                        </button>
                      </ActionRow>
                    </tr>
                  ),
                )
              ) : (
                <tr>
                  <Row colSpan={7} value="No delivery order yet" />
                </tr>
              )}
            </Table>

            <Pagination
              pageNumber={directoryList?.pageNumber}
              pageSize={directoryList?.pageSize}
              totalCount={directoryList?.totalCount}
              totalPage={directoryList?.totalPage}
              onFetch={fetchMore}
            />
          </div>
        </main>
      </section>

      {showUpdateModal && (
        <Modal
          header="Update Service Directory"
          onClose={() => {
            setShowUpdateModal(false);
            setSelectedDirectory(null);
            resetErrors();
          }}
        >
          <form onSubmit={updateDirectory}>
            <InputField
              boxStyle="mb-[24px]"
              placeholder="Subscription Fee"
              label="Subscription Fee"
              name="subscriptionFee"
              type="number"
              value={selectedDirectory?.subscriptionFee}
              onChange={(e: any) => onFormChange(e, setSelectedDirectory)}
              error={errors?.SubscriptionFee[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  SubscriptionFee: [],
                }));
              }}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Convenience Fee"
              label="Convenience Fee"
              name="convenienceFee"
              type="number"
              value={selectedDirectory?.convenienceFee}
              onChange={(e: any) => onFormChange(e, setSelectedDirectory)}
              error={errors?.ConvenienceFee[0]}
              onBlur={() => {
                setErrors((state: any) => ({
                  ...state,
                  ConvenienceFee: [],
                }));
              }}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);
                  setSelectedDirectory(null);
                  resetErrors();
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default CreateServiceDirectory;
