import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  addUpdateLoanParameter,
  addUpdateReminder,
  approveLoan,
  declineLoan,
  deleteLoanParameter,
  getLoanParameter,
  getLoanRequestById,
  getLoanRequestHistory,
  getReminders,
  toggleLoanParameter,
} from "../api/loans.api";
import notification from "core/helpers/notification";

type LoanState = {
  isLoading: boolean;
  loanRequestList: LoanList;
  singleLoanRequest: LoanRequest | null;
  loanParameter: LoanParameter | null;
  messageSchedules: MessageSchedule[];
  getLoanParameter: () => Promise<void>;
  toggleLoanParameter: (id: string, status: boolean) => Promise<void>;
  deleteLoanParameter: (id: string) => Promise<UIResponse>;
  getLoanRequestById: (id: string) => Promise<void>;
  getLoanRequests: (
    query: LoanQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  addUpdateLoanParameter: (
    data: NewLoanParameter,
    id: string,
  ) => Promise<UIResponse>;
  addMessageSchedules: (data: MessageSchedule[]) => Promise<UIResponse>;
  getMessageSchedules: () => Promise<void>;
  approveDeclineLoan: (
    requestId: string,
    comment: string,
    isApproved: boolean,
  ) => Promise<void>;
  reset: () => void;
};

const initialState = {
  isLoading: false,
  loanRequestList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
  },
  singleLoanRequest: null,
  loanParameter: null,
  messageSchedules: [],
};

const useLoanStore = create<LoanState>()(
  devtools(
    persist(
      (set, get): LoanState => ({
        ...initialState,
        getLoanParameter: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getLoanParameter();
          if (res?.isSuccessful) {
            set({
              loanParameter: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ loanParameter: null, isLoading: false });
          }
        },
        getMessageSchedules: async () => {
          set({ isLoading: true });

          var res: UIResponse = await getReminders();
          if (res?.isSuccessful) {
            set({
              messageSchedules: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ messageSchedules: [], isLoading: false });
          }
        },
        addMessageSchedules: async (data) => {
          set({ isLoading: true });
          var res: UIResponse = await addUpdateReminder(data);

          if (res?.isSuccessful) {
            get().getMessageSchedules();
          } else {
            res.data = res?.data?.errors;
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : "One or more error validation occurred",
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        addUpdateLoanParameter: async (data, id) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateLoanParameter(data, id);

          if (res?.isSuccessful) {
            set({ loanParameter: { ...res?.data?.data } });
          } else {
            res.data = res?.data?.errors;
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : "One or more error validation occurred",
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        toggleLoanParameter: async (id, status) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleLoanParameter(id);

          if (res?.isSuccessful) {
            set((state) => ({
              loanParameter: {
                ...state.loanParameter!,
                status: status,
              },
            }));
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return;
        },
        deleteLoanParameter: async (id) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteLoanParameter(id);
          if (res?.isSuccessful) {
            set({ loanParameter: null });
          }

          set({ isLoading: false });
          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        getLoanRequestById: async (id) => {
          set({ isLoading: true });

          var res: UIResponse = await getLoanRequestById(id);
          if (res?.isSuccessful) {
            set({
              singleLoanRequest: res?.data?.data,
              isLoading: false,
            });
          } else {
            notification({
              message: res?.isSuccessful
                ? res?.data?.message
                : res?.data?.error?.message?.length > 1
                  ? res?.data?.error?.message
                  : res?.data?.message,
              type: res?.isSuccessful ? "success" : "danger",
            });
            set({ singleLoanRequest: null, isLoading: false });
          }
        },
        getLoanRequests: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getLoanRequestHistory(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              loanRequestList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        approveDeclineLoan: async (requestId, comment, isApproved) => {
          set({ isLoading: true });

          var res: UIResponse = isApproved
            ? await approveLoan(requestId)
            : await declineLoan(requestId, comment);

          if (res?.isSuccessful) {
            get().getLoanRequestById(requestId);
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        reset: () => {
          set({
            ...initialState,
          });
        },
      }),
      {
        name: "loanStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useLoanStore;
