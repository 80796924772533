import { apicall } from "./apicall";

export const getCustomerData = (query: UserDataQueryParam) => {
  return apicall({
    endpoint: "/auth/getcustomerdatacenter",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};

export const getEventAnalytics = (query: EventAnalyticsQuery) => {
  const ids =
    query?.locationId?.length > 0
      ? query?.locationId?.map((loc) => loc?.value)
      : [];

  return apicall({
    endpoint: "/analytics/user/events",
    method: "POST",
    body: { ...query, locationId: ids, event: +query?.event },
    auth: true,
  });
};

export const sendSmsNotification = (data: EventMessageData) => {
  const { messageTitle, ...rest } = data;

  return apicall({
    endpoint: "/analytics/user/sms/sendNotification",
    method: "POST",
    body: rest,
    auth: true,
  });
};

export const sendPreviewSmsNotification = (data: SmsPreview) => {
  return apicall({
    endpoint: "/analytics/preview/sms/sendNotification",
    method: "POST",
    body: data,
    auth: true,
  });
};

export const sendFireBaseNotification = (
  data: EventMessageData,
  saveNotification: boolean,
) => {
  return apicall({
    endpoint: "/analytics/user/firebase/sendNotification",
    method: "POST",
    body: { ...data, shouldSaveNotification: saveNotification },
    auth: true,
  });
};

export const uploadFile = (data: File[]) => {
  const formData = new FormData();

  if (data?.length > 0) {
    data?.forEach((file: any, i: any) => {
      formData.append(`imageFiles${i}`, file);
    });
  }

  return apicall({
    endpoint: "/analytics/imageUpload",
    method: "POST",
    auth: true,
    body: formData,
    multipart: true,
  });
};

export const sendEmailNotification = (data: EventMessageData) => {
  const ids =
    data?.analyticQuery?.locationId?.length > 0
      ? data?.analyticQuery?.locationId?.map((loc) => loc?.value)
      : [];

  return apicall({
    endpoint: "/analytics/user/email/sendNotification",
    method: "POST",
    body: {
      ...data,
      analyticQuery: {
        ...data.analyticQuery,
        locationId: ids,
      },
    },
    auth: true,
  });
};

export const sendPreviewEmailNotification = (data: EmailPreview) => {
  return apicall({
    endpoint: "/analytics/preview/email/sendNotification",
    method: "POST",
    body: data,
    auth: true,
  });
};
