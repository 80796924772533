import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addUpdateProperty,
  deleteProperty,
  deletePropertyImage,
  getAccommodationDashboard,
  getInspectionRequest,
  getProperty,
  getPropertyByID,
  toggleAccommodation,
  updateInspectionStatus,
  updatePropertyStatus,
} from "../api/accommodation.api";

type StoreState = {
  isLoading: boolean;
  analytics: AccommodationAnalytics;
  propertyList: PropertyList;
  property: any;
  inspectionRequestList: {
    requests: InspectionRequest[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getDashboardAnalytics: (query: DateLocationQuery) => Promise<void>;
  getProperties: (
    query: AccommodationQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getPropertyById: (accommodationId: string) => Promise<void>;
  addUpdateProperty: (
    property: NewProperty,
    accommodationId: string,
  ) => Promise<UIResponse>;
  toggleProperty: (
    accommodationId: string,
    visibility: boolean,
  ) => Promise<void>;
  deleteProperty: (accommodationId: string) => Promise<void>;
  deletePropertyImage: (
    category: string,
    clotheId: string,
    image: string,
  ) => Promise<void>;
  getInspections: (
    query: InspectionQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  updateInspection: (
    requestId: string,
    status: string,
    reason: string,
  ) => Promise<UIResponse>;
  updatePropertyStatus: (
    accommodationId: string,
    status: string,
  ) => Promise<UIResponse>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  analytics: {
    listedApartment: 0,
    vacantApartment: 0,
    rentedApartment: 0,
    totalRentCommission: 0,
    totalViewCommission: 0,
    totalViews: 0,
    totalCommission: 0,
    averageRentCommission: 0,
  },
  propertyList: {
    properties: [],
    pageNumber: 0,
    pageSize: 15,
    totalCount: 0,
    totalPage: 0,
  },
  inspectionRequestList: {
    requests: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  property: null,
};

const useAccommodationStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getAccommodationDashboard(query);
          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getInspections: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getInspectionRequest(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              inspectionRequestList: {
                requests: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getProperties: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getProperty(query, pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              propertyList: {
                properties: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getPropertyById: async (accommodationId) => {
          set({ isLoading: true });

          var res: UIResponse = await getPropertyByID(accommodationId);
          if (res?.isSuccessful) {
            set({
              property: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ property: null, isLoading: false });
          }
        },
        addUpdateProperty: async (property, accommodationId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateProperty(
            property,
            accommodationId,
          );

          if (res?.isSuccessful && accommodationId?.length > 1) {
            get().getPropertyById(accommodationId);
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deletePropertyImage: async (category, accommodationId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deletePropertyImage(
            category,
            accommodationId,
            image,
          );

          if (res?.isSuccessful) {
            get().getPropertyById(accommodationId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        deleteProperty: async (accommodationId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteProperty(accommodationId);

          if (res?.isSuccessful) {
            set((state) => ({
              propertyList: {
                ...state.propertyList,
                properties: state?.propertyList?.properties?.filter(
                  (property) => property?.accommodationId !== accommodationId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Property has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleProperty: async (accommodationId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleAccommodation(
            accommodationId,
            visibility,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              propertyList: {
                ...state.propertyList,
                properties: state?.propertyList?.properties?.map(
                  (property: any) =>
                    property?.accommodationId === accommodationId
                      ? { ...property, visibility: visibility }
                      : property,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        updateInspection: async (requestId, status, reason) => {
          set({ isLoading: true });

          var res: UIResponse = await updateInspectionStatus(
            requestId,
            status,
            reason,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              inspectionRequestList: {
                ...state.inspectionRequestList,
                requests: state?.inspectionRequestList?.requests?.map(
                  (request) =>
                    request?.requestId === requestId
                      ? { ...request, status: status }
                      : request,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Inspection status has been updated successfully to ${status}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        updatePropertyStatus: async (accommodationId, status) => {
          set({ isLoading: true });

          var res: UIResponse = await updatePropertyStatus(
            accommodationId,
            status,
          );

          if (res?.isSuccessful) {
            set((state) => ({
              propertyList: {
                ...state?.propertyList,
                properties: state?.propertyList?.properties?.map((property) =>
                  property?.accommodationId === accommodationId
                    ? { ...property, status: status }
                    : property,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Property status has been updated successfully to ${status}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "accommodationStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAccommodationStore;
