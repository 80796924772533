import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  addPrivilege,
  addRolePrivileges,
  addSupportPersonnel,
  assignRole,
  deletePrivilege,
  deleteRolePrivileges,
  deleteSupportUser,
  getAdmins,
  getFunctionality,
  getPrivilege,
  getRolePrivileges,
  getRoles,
  getSettlementLog,
  getSupportPersonnels,
  manageAdmin,
  manageFunctionality,
  markMerchantAsPaid,
  merchantExportToExcel,
  updatePrivilege,
  updateSupportPersonnel,
} from "../api/managementapi";
import { ADMIN_ACTION, USER_STATUS } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import { downloadExcelFile } from "core/helpers/exportToExcel";

type AdminState = {
  isLoading: boolean;
  admins: Admin[] | [];
  settlementLogList: SettlementLogList;
  supportPersonnels: SupportPersonnel[] | [];
  functionality: Functionality[] | [];
  roles: Roles[] | [];
  privileges: Roles[] | [];
  rolePrivileges: RolePrivileges[] | [];
  getAdmins: (locationFilter: string) => void;
  manageAdmin: (id: string, action: ManageActions) => Promise<UIResponse>;
  getSettlementLog: (query: LogQueryParam) => Promise<void>;
  markAsPaid: (logs: SettlementLog[]) => Promise<void>;
  exportToExcel: (logs: SettlementLog[]) => Promise<void>;
  getSupportPersonnels: () => Promise<void>;
  reset: () => void;
  addSupportPersonnel: (support: NewSupportPersonnel) => Promise<UIResponse>;
  updateSupportPersonnel: (support: SupportPersonnel) => Promise<UIResponse>;
  getFunctionality: () => Promise<void>;
  manageFunctionality: (id: string, isActive: boolean) => Promise<void>;
  getRoles: () => Promise<void>;
  getPrivilege: () => Promise<void>;
  getRolePrivilege: () => Promise<void>;
  addPrivilege: (name: string) => Promise<UIResponse>;
  addRolePrivilege: (
    roleId: string,
    privilegeId: string[],
  ) => Promise<UIResponse>;
  updatePrivilege: (id: string, name: string) => Promise<UIResponse>;
  deletePrivilege: (id: string) => Promise<void>;
  deleteRolePrivilege: (id: string, roleId: string) => Promise<void>;
  deleteSupportPersonnel: (supportId: string) => Promise<void>;
  assignRole: (userId: string, roleId: string) => Promise<void>;
};

const defaultState = {
  isLoading: false,
  admins: [],
  settlementLogList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
    totalAmount: 0,
  },
  supportPersonnels: [],
  functionality: [],
  roles: [],
  rolePrivileges: [],
  privileges: [],
};

const useAdminStore = create<AdminState>()(
  devtools(
    persist(
      (set, get): AdminState => ({
        ...defaultState,
        getAdmins: async (locationFilter) => {
          set({ isLoading: true });
          var res = await getAdmins(locationFilter);

          if (res?.isSuccessful) {
            set({ admins: res?.data?.data });
          }
          set({ isLoading: false });
        },
        getSettlementLog: async (query) => {
          set({ isLoading: true });

          if (query?.locationFilter?.length > 1) {
            var res = await getSettlementLog(query);

            var calculatedTotal = 0;

            if (res?.data?.data?.length > 0) {
              calculatedTotal = res?.data?.data?.reduce(
                (acc: any, current: any) => acc + current.amount,
                0,
              );
            } else {
              calculatedTotal = res?.data?.data?.totalAmount;
            }

            if (res?.isSuccessful) {
              set({
                settlementLogList: {
                  pageNumber: 0,
                  pageSize: 0,
                  totalCount: 0,
                  totalPage: 0,
                  data: [...res?.data?.data],
                  totalAmount: calculatedTotal,
                },
              });
            }
          }

          set({ isLoading: false });

          return;
        },
        manageAdmin: async (id, action) => {
          set({ isLoading: true });
          var res = await manageAdmin(id, action);

          if (res?.isSuccessful) {
            if (action !== ADMIN_ACTION.remove) {
              set((state) => ({
                admins: state.admins.map((admin: Admin) =>
                  admin.adminId === id
                    ? {
                        ...admin,
                        status:
                          action === ADMIN_ACTION.deactivate
                            ? USER_STATUS.suspended
                            : USER_STATUS.approved,
                      }
                    : admin,
                ),
              }));
            } else {
              set((state) => ({
                admins: state.admins.filter(
                  (admin: Admin) => admin.adminId !== id,
                ),
              }));
            }
          } else {
          }

          notification({
            message: res?.isSuccessful
              ? `admin user has been ${action}d successfully`
              : `process could not be completed`,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        markAsPaid: async (logs) => {
          set({ isLoading: true });
          var res = await markMerchantAsPaid(logs);

          if (res?.isSuccessful) {
            set((state) => ({
              settlementLogList: {
                ...state.settlementLogList,
                data: state.settlementLogList?.data?.map(
                  (log: SettlementLog) =>
                    logs.some((data) => data?.id === log?.id)
                      ? {
                          ...log,
                          paymentStatus: "paid",
                        }
                      : log,
                ),
              },
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return;
        },
        exportToExcel: async (logs) => {
          set({ isLoading: true });
          var res = await merchantExportToExcel(logs);

          if (res?.status === 200) {
            await downloadExcelFile(res?.data);
          }

          notification({
            message: res?.isSuccessful
              ? "You have successfully generated a download link for the selected items"
              : "Error downloading settlement list. Please, check your network connection.",
            type: res?.isSuccessful ? "success" : "danger",
          });
          set({ isLoading: false });
          return;
        },
        getSupportPersonnels: async () => {
          set({ isLoading: true });
          var res = await getSupportPersonnels();

          if (res?.isSuccessful) {
            set({ supportPersonnels: res?.data });
          }

          set({ isLoading: false });
        },
        addSupportPersonnel: async (support: NewSupportPersonnel) => {
          set({ isLoading: true });
          var res = await addSupportPersonnel(support);

          if (res?.isSuccessful) {
            set((state) => ({
              supportPersonnels: [
                {
                  ...res?.data?.data,
                },
                ...state.supportPersonnels,
              ],
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.title ?? res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        updateSupportPersonnel: async (support: SupportPersonnel) => {
          set({ isLoading: true });
          var res = await updateSupportPersonnel(support);

          if (res?.isSuccessful) {
            set((state) => ({
              supportPersonnels: state.supportPersonnels.map(
                (sup: SupportPersonnel) =>
                  sup.supportId === support?.supportId
                    ? {
                        ...res?.data?.data,
                      }
                    : sup,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        getFunctionality: async () => {
          set({ isLoading: true });
          var res = await getFunctionality();

          if (res?.isSuccessful) {
            set({ functionality: res?.data });
          }

          set({ isLoading: false });
        },
        manageFunctionality: async (id, isActive) => {
          set({ isLoading: true });

          var res = await manageFunctionality(id, isActive);

          if (res?.isSuccessful) {
            set((state) => ({
              functionality: state.functionality.map((func: Functionality) =>
                func.id === id
                  ? {
                      ...res?.data?.data,
                    }
                  : func,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        getRoles: async () => {
          set({ isLoading: true });
          var res = await getRoles();

          if (res?.isSuccessful) {
            set({ roles: res?.data });
          }

          set({ isLoading: false });
        },
        getPrivilege: async () => {
          set({ isLoading: true });
          var res = await getPrivilege();

          if (res?.isSuccessful) {
            set({ privileges: res?.data });
          }

          set({ isLoading: false });
        },
        getRolePrivilege: async () => {
          set({ isLoading: true });
          var res = await getRolePrivileges();

          if (res?.isSuccessful) {
            set({ rolePrivileges: res?.data });
          }

          set({ isLoading: false });
        },
        addPrivilege: async (name) => {
          set({ isLoading: true });
          var res = await addPrivilege(name);

          if (res?.isSuccessful) {
            set((state) => ({
              privileges: [
                {
                  ...res?.data?.data,
                },
                ...state.privileges,
              ],
            }));
          }

          notification({
            message: res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        addRolePrivilege: async (roleId, privilegeId) => {
          set({ isLoading: true });
          var res = await addRolePrivileges({ roleId, privilegeId });

          if (res?.isSuccessful) {
            get().getRolePrivilege();
          }

          notification({
            message: res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deletePrivilege: async (id) => {
          set({ isLoading: true });
          var res = await deletePrivilege(id);

          if (res?.isSuccessful) {
            set((state) => ({
              privileges: state.privileges.filter((loc) => loc.id !== id),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Privilege has been deleted successfully"
              : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        deleteRolePrivilege: async (id, roleId) => {
          set({ isLoading: true });
          var res = await deleteRolePrivileges(id);

          if (res?.isSuccessful) {
            await get().getRolePrivilege();

            // TODO: update state from context
          }

          notification({
            message: res?.isSuccessful
              ? "Role privilege has been deleted successfully"
              : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        assignRole: async (userId, roleId) => {
          set({ isLoading: true });
          var res = await assignRole(userId, roleId);

          if (res?.isSuccessful) {
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        updatePrivilege: async (id, name) => {
          set({ isLoading: true });
          var res = await updatePrivilege(id, name);

          if (res?.isSuccessful) {
            set((state) => ({
              privileges: state.privileges.map((sup) =>
                sup.id === id
                  ? {
                      ...res?.data?.data,
                    }
                  : sup,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful ? res?.data?.message : res?.data?.title,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deleteSupportPersonnel: async (supportId) => {
          set({ isLoading: true });
          var res = await deleteSupportUser(supportId);

          if (res?.isSuccessful) {
            set((state) => ({
              supportPersonnels: state.supportPersonnels.filter(
                (admin) => admin?.supportId !== supportId,
              ),
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Support has been deleted successfully"
              : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return;
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "adminStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAdminStore;
