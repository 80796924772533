import Accordion from "core/components/Accordion";
import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { deleteIcon, editIcon, users } from "core/consts/images";
import { actionbtn, btn } from "core/consts/styling";
import { formatCurrency, getDateTime } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect, useState } from "react";

const SettingsType = {
  service: "service",
  fee: "fee",
  location: "location",
  weight: "weight",
};

const DeliverySettings = () => {
  // settings state
  const deliveryLocations = useTransportationStore((store) => store.locations);
  const convenienceFees = useTransportationStore(
    (store) => store.convenienceFees,
  );
  const weights = useTransportationStore((store) => store.weights);
  const transportServices = useTransportationStore((store) => store.services);

  // selected
  const [selected, setSelected] = useState<any>(null);

  const [showServiceForm, setShowServiceForm] = useState(false);
  const [, setShowLocationForm] = useState(false);
  const [, setShowFeeForm] = useState(false);
  const [, setShowWeightForm] = useState(false);

  // get actions
  const getDeliveryLocationAction = useTransportationStore(
    (store) => store.getDeliveryLocations,
  );
  const getConvenienceFeeAction = useTransportationStore(
    (store) => store.getConvenienceFees,
  );
  const getWeightAction = useTransportationStore((store) => store.getWeights);
  const getServiceAction = useTransportationStore((store) => store.getServices);

  // add update actions
  const addUpdateServiceAction = useTransportationStore(
    (store) => store.addUpdateService,
  );
  const addUpdateFeeAction = useTransportationStore(
    (store) => store.addUpdateConvenienceFee,
  );
  const addUpdateWeightAction = useTransportationStore(
    (store) => store.addUpdateWeight,
  );
  const addUpdateLocationAction = useTransportationStore(
    (store) => store.addUpdateDeliveryLocation,
  );

  // delete actions
  const deleteServiceAction = useTransportationStore(
    (store) => store.deleteService,
  );
  const deleteFeeAction = useTransportationStore(
    (store) => store.deleteConvenienceFee,
  );
  const deleteWeightAction = useTransportationStore(
    (store) => store.deleteWeight,
  );
  const deleteLocationAction = useTransportationStore(
    (store) => store.deleteLocation,
  );

  // form state
  const [newService, setNewService] = useState<NewService>({
    serviceName: "",
    description: "",
  });

  const [newLocation, setNewLocation] = useState<NewDeliveryLocation>({
    dropOffArea: "",
    pickUpArea: "",
    expressDeliveryCost: 0,
    normalDeliveryCost: 0,
  });

  const [newWeight, setNewWeight] = useState<NewWeightRange>({
    description: "",
    range: "",
  });

  const [newFee, setNewFee] = useState<NewConvenienceFee>({
    amount: 0,
    weightRange: "",
  });

  // error state
  const [serviceErrors, setServiceErrors] = useState({
    ServiceName: [],
    Description: [],
  });

  const [locationErrors, setLocationErrors] = useState({
    DropOffArea: [],
    PickUpArea: [],
    ExpressDeliveryCost: [],
    NormalDeliveryCost: [],
  });

  const [weightErrors, setWeightErrors] = useState({
    Description: [],
    Range: [],
  });

  const [feeErrors, setFeeErrors] = useState({
    Amount: [],
    WeightRange: [],
  });

  // handle form changes
  const onFormChange = (type: string, e: any) => {
    const { name, value } = e?.target;

    switch (type) {
      case SettingsType.service:
        setNewService((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.location:
        setNewLocation((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.fee:
        setNewFee((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.weight:
        setNewWeight((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  // handle errors
  const onErrorChange = (type: string, name: string, value: string[]) => {
    switch (type) {
      case SettingsType.service:
        setServiceErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.location:
        setLocationErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.fee:
        setFeeErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      case SettingsType.weight:
        setWeightErrors((state) => ({
          ...state,
          [name]: value,
        }));
        break;
      default:
        break;
    }
  };

  // close update
  const closeUpdateForm = (type: string) => {
    switch (type) {
      case SettingsType.service:
        setShowServiceForm(false);
        setSelected(null);
        setNewService({
          description: "",
          serviceName: "",
        });
        setServiceErrors({
          Description: [],
          ServiceName: [],
        });
        break;
      case SettingsType.location:
        setShowLocationForm(false);
        setSelected(null);
        setNewLocation({
          dropOffArea: "",
          pickUpArea: "",
          expressDeliveryCost: 0,
          normalDeliveryCost: 0,
        });
        setLocationErrors({
          DropOffArea: [],
          PickUpArea: [],
          ExpressDeliveryCost: [],
          NormalDeliveryCost: [],
        });
        break;
      case SettingsType.fee:
        setShowFeeForm(false);
        setSelected(null);
        setNewFee({
          amount: 0,
          weightRange: "",
        });
        setFeeErrors({
          Amount: [],
          WeightRange: [],
        });
        break;
      case SettingsType.weight:
        setShowWeightForm(false);
        setSelected(null);
        setNewWeight({
          description: "",
          range: "",
        });
        setWeightErrors({
          Description: [],
          Range: [],
        });
        break;
      default:
        break;
    }
  };

  // validations
  const serviceValidation = (data: NewService) => {
    var isValid = true;

    if (data?.serviceName?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.service, "ServiceName", [
        "Service Name is required",
      ]);
    }

    if (data?.description?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.service, "Description", [
        "Description is required",
      ]);
    }

    return isValid;
  };

  const locationValidation = (data: NewDeliveryLocation) => {
    var isValid = true;

    if (data?.pickUpArea?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.location, "PickUpArea", [
        "Pick up Area is required",
      ]);
    }

    if (data?.dropOffArea?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.location, "DropOffArea", [
        "Drop Off Area is required",
      ]);
    }

    if (data?.expressDeliveryCost < 0) {
      isValid = false;
      onErrorChange(SettingsType.location, "ExpressDeliveryCost", [
        "Express Delivery Cost must not be less than 0",
      ]);
    }

    if (data?.normalDeliveryCost < 0) {
      isValid = false;
      onErrorChange(SettingsType.location, "NormalDeliveryCost", [
        "Normal Delivery Cost must not be less than 0",
      ]);
    }

    return isValid;
  };

  const feeValidation = (data: NewConvenienceFee) => {
    var isValid = true;

    if (data?.weightRange?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.fee, "WeightRange", [
        "Weight Range is required",
      ]);
    }

    if (data?.amount < 0) {
      isValid = false;
      onErrorChange(SettingsType.fee, "Amount", [
        "Amount must not be less than 0",
      ]);
    }

    return isValid;
  };

  const weightValidation = (data: NewWeightRange) => {
    var isValid = true;

    if (data?.range?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.weight, "Range", ["Weight Range is required"]);
    }

    if (data?.description?.length < 1) {
      isValid = false;
      onErrorChange(SettingsType.weight, "Description", [
        "Description is required",
      ]);
    }

    return isValid;
  };

  // add update
  const addUpdateService = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (serviceValidation(newService)) {
      var res = await addUpdateServiceAction(
        newService,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewService({
          description: "",
          serviceName: "",
        });

        if (isUpdate) {
          setSelected(null);
          setShowServiceForm(false);
        }
      } else {
        setServiceErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const addUpdateFee = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (feeValidation(newFee)) {
      var res = await addUpdateFeeAction(newFee, isUpdate ? selected?.id : "");

      if (res?.isSuccessful) {
        setNewFee({
          amount: 0,
          weightRange: "",
        });

        if (isUpdate) {
          setSelected(null);
          setShowFeeForm(false);
        }
      } else {
        setFeeErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const addUpdateLocation = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (locationValidation(newLocation)) {
      var res = await addUpdateLocationAction(
        newLocation,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewLocation({
          dropOffArea: "",
          pickUpArea: "",
          expressDeliveryCost: 0,
          normalDeliveryCost: 0,
        });

        if (isUpdate) {
          setSelected(null);
          setShowLocationForm(false);
        }
      } else {
        setLocationErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const addUpdateWeight = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (weightValidation(newWeight)) {
      var res = await addUpdateWeightAction(
        newWeight,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewWeight({
          description: "",
          range: "",
        });

        if (isUpdate) {
          setSelected(null);
          setShowWeightForm(false);
        }
      } else {
        setLocationErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  // delete
  const deleteSettings = async (type: string, id: string) => {
    if (window.confirm(`Click 'OK' to delete this "${type}"`)) {
      switch (type) {
        case SettingsType.service:
          await deleteServiceAction(id);
          break;
        case SettingsType.weight:
          await deleteWeightAction(id);
          break;
        case SettingsType.location:
          await deleteLocationAction(id);
          break;
        case SettingsType.fee:
          await deleteFeeAction(id);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    deliveryLocations?.length < 1 && getDeliveryLocationAction();
    convenienceFees?.length < 1 && getConvenienceFeeAction();
    weights?.length < 1 && getWeightAction();
    transportServices?.length < 1 && getServiceAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={users} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Delivery Settings
          </h5>
        </header>

        <section className="mb-[28px]">
          <Accordion id="createService" title="Create Service Type">
            <section className="flex flex-col gap-5 lg:flex-row">
              <div className="w-full rounded-[5px] bg-white p-5 lg:w-4/12">
                <form onSubmit={(e) => addUpdateService(e, false)}>
                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="Service Name"
                    name="serviceName"
                    isRequired
                    value={newService?.serviceName}
                    onChange={(e: any) => onFormChange(SettingsType.service, e)}
                    error={serviceErrors?.ServiceName[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.service, "ServiceName", [])
                    }
                  />

                  <TextField
                    boxStyle="mb-[18px]"
                    placeholder="Description "
                    name="description"
                    isRequired
                    value={newService?.description}
                    onChange={(e: any) => onFormChange(SettingsType.service, e)}
                    error={serviceErrors?.Description[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.service, "Description", [])
                    }
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={[
                    "Service Type",
                    "Description",
                    "Last Updated",
                    "Last Updated By",
                    "Action",
                  ]}
                >
                  {transportServices?.length > 0 ? (
                    transportServices?.map((service) => (
                      <tr key={service?.id}>
                        <Row value={service?.serviceName} />
                        <Row value={service?.description} />
                        <Row value={getDateTime(service?.lastUpdated)} />
                        <Row value={service?.lastUpdatedBy} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            disabled
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                            onClick={() => {
                              setSelected(service);
                              setNewService((state) => ({
                                ...state,
                                description: service?.description,
                                serviceName: service?.serviceName,
                              }));
                              setShowServiceForm(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteSettings(SettingsType.service, service?.id);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={6} value="No services yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion id="createLocations" title="Create Locations">
            <section className="flex flex-col gap-5 lg:flex-row">
              <div className="w-full rounded-[5px] bg-white p-5 lg:w-4/12">
                <form onSubmit={(e) => addUpdateLocation(e, false)}>
                  <InputField
                    boxStyle="mb-[18px]"
                    label="Pick Up Area"
                    placeholder="Pick Up Area"
                    name="pickUpArea"
                    isRequired
                    value={newLocation?.pickUpArea}
                    onChange={(e: any) =>
                      onFormChange(SettingsType.location, e)
                    }
                    error={locationErrors?.PickUpArea[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.location, "PickUpArea", [])
                    }
                  />

                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="Drop Off Area"
                    label="Drop Off Area"
                    name="dropOffArea"
                    isRequired
                    value={newLocation?.dropOffArea}
                    onChange={(e: any) =>
                      onFormChange(SettingsType.location, e)
                    }
                    error={locationErrors?.DropOffArea[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.location, "DropOffArea", [])
                    }
                  />

                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="Normal Delivery Cost"
                    label="Normal Delivery Cost"
                    name="normalDeliveryCost"
                    type="number"
                    isRequired
                    value={newLocation?.normalDeliveryCost}
                    onChange={(e: any) =>
                      onFormChange(SettingsType.location, e)
                    }
                    error={locationErrors?.NormalDeliveryCost[0]}
                    onBlur={() =>
                      onErrorChange(
                        SettingsType.location,
                        "NormalDeliveryCost",
                        [],
                      )
                    }
                  />

                  <InputField
                    boxStyle="mb-[18px]"
                    placeholder="Express Delivery Cost"
                    label="Express Delivery Cost"
                    name="expressDeliveryCost"
                    type="number"
                    isRequired
                    value={newLocation?.expressDeliveryCost}
                    onChange={(e: any) =>
                      onFormChange(SettingsType.location, e)
                    }
                    error={locationErrors?.ExpressDeliveryCost[0]}
                    onBlur={() =>
                      onErrorChange(
                        SettingsType.location,
                        "ExpressDeliveryCost",
                        [],
                      )
                    }
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={[
                    "Pick Up Area",
                    "Drop Off Area",
                    "Normal Delivery Cost",
                    "Express Delivery Cost",
                    "Last Updated",
                    "Last Updated By",
                    "Action",
                  ]}
                >
                  {deliveryLocations?.length > 0 ? (
                    deliveryLocations?.map((location) => (
                      <tr key={location?.id}>
                        <Row value={location?.pickUpArea} />
                        <Row value={location?.dropOffArea} />
                        <Row
                          value={formatCurrency(location?.normalDeliveryCost)}
                        />
                        <Row
                          value={formatCurrency(location?.expressDeliveryCost)}
                        />
                        <Row value={getDateTime(location?.lastUpdated)} />
                        <Row value={location?.lastUpdatedBy} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            disabled
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                            onClick={() => {
                              setSelected(location);
                              setNewLocation((state) => ({
                                ...state,
                                dropOffArea: location?.dropOffArea,
                                expressDeliveryCost:
                                  location?.expressDeliveryCost,
                                normalDeliveryCost:
                                  location?.normalDeliveryCost,
                                pickUpArea: location?.pickUpArea,
                              }));
                              setShowLocationForm(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteSettings(
                                SettingsType.location,
                                location?.id,
                              );
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={7} value="No delivery location yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion id="createWeightRange" title="Create Weight Range">
            <section className="flex flex-col gap-5 lg:flex-row">
              <div className="w-full rounded-[5px] bg-white p-5 lg:w-4/12">
                <form onSubmit={(e) => addUpdateWeight(e, false)}>
                  <InputField
                    boxStyle="mb-[18px]"
                    label="Weight Range"
                    placeholder="Weight Range"
                    name="range"
                    isRequired
                    value={newWeight?.range}
                    onChange={(e: any) => onFormChange(SettingsType.weight, e)}
                    error={weightErrors?.Range[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.weight, "Range", [])
                    }
                  />

                  <TextField
                    boxStyle="mb-[18px]"
                    placeholder="Description"
                    name="description"
                    isRequired
                    value={newWeight?.description}
                    onChange={(e: any) => onFormChange(SettingsType.weight, e)}
                    error={weightErrors?.Description[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.weight, "Description", [])
                    }
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={[
                    "Weight Range",
                    "Description",
                    "Last Updated",
                    "Last Updated By",
                    "Action",
                  ]}
                >
                  {weights?.length > 0 ? (
                    weights?.map((weight) => (
                      <tr key={weight?.id}>
                        <Row value={weight?.range} />
                        <Row value={weight?.description} />
                        <Row value={getDateTime(weight?.lastUpdated)} />
                        <Row value={weight?.lastUpdatedBy} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            disabled
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                            onClick={() => {
                              setSelected(weight);
                              setNewWeight({
                                description: "",
                                range: "",
                              });
                              setShowWeightForm(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteSettings(SettingsType.weight, weight?.id);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={5} value="No weight range yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion id="convenienceFee" title="Create Convenience Fee">
            <section className="flex flex-col gap-5 lg:flex-row">
              <div className="w-full rounded-[5px] bg-white p-5 lg:w-4/12">
                <form onSubmit={(e) => addUpdateFee(e, false)}>
                  <SelectField
                    boxStyle="mb-[18px]"
                    label="Select Weight Range"
                    name="weightRange"
                    defaultName="Select weight range"
                    defaultValue=""
                    options={
                      weights?.length > 0
                        ? [
                            ...weights?.map((weight) => ({
                              name: weight?.range,
                              value: weight?.range,
                            })),
                          ]
                        : []
                    }
                    value={newFee?.weightRange}
                    onChange={(e: any) => onFormChange(SettingsType.fee, e)}
                    error={feeErrors?.WeightRange[0]}
                    onBlur={() =>
                      onErrorChange(SettingsType.fee, "WeightRange", [])
                    }
                  />

                  <InputField
                    boxStyle="mb-[18px]"
                    label="Amount"
                    placeholder="Amount"
                    name="amount"
                    type="number"
                    isRequired
                    value={newFee?.amount}
                    onChange={(e: any) => onFormChange(SettingsType.fee, e)}
                    error={feeErrors?.Amount[0]}
                    onBlur={() => onErrorChange(SettingsType.fee, "Amount", [])}
                  />

                  <button className={`${btn} w-full bg-brand text-white`}>
                    Submit
                  </button>
                </form>
              </div>
              <div className="w-full lg:w-8/12">
                <Table
                  headers={[
                    "Weight Range",
                    "Amount",
                    "Last Updated",
                    "Last Updated By",
                    "Action",
                  ]}
                >
                  {convenienceFees?.length > 0 ? (
                    convenienceFees?.map((fee) => (
                      <tr key={fee?.id}>
                        <Row value={fee?.weightRange} />
                        <Row value={formatCurrency(fee?.amount)} />
                        <Row value={getDateTime(fee?.lastUpdated)} />
                        <Row value={fee?.lastUpdatedBy} />
                        <ActionRow
                          style={`flex items-center justify-center gap-3`}
                        >
                          <button
                            disabled
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2 disabled:cursor-not-allowed"
                            onClick={() => {
                              setSelected(fee);
                              setNewFee({
                                amount: 0,
                                weightRange: "",
                              });
                              setShowFeeForm(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              loading="lazy"
                              className="h-[16px]"
                              alt="edit"
                            />
                          </button>
                          <button
                            className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                            onClick={() => {
                              deleteSettings(SettingsType.fee, fee?.id);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="h-[16px]"
                              loading="lazy"
                              alt="delete"
                            />
                          </button>
                        </ActionRow>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <Row colSpan={5} value="No convenience fee yet" />
                    </tr>
                  )}
                </Table>
              </div>
            </section>
          </Accordion>
        </section>
      </div>

      {showServiceForm && (
        <Modal
          header="Update Service"
          onClose={() => closeUpdateForm(SettingsType.service)}
        >
          <form onSubmit={(e) => addUpdateService(e, true)}>
            <InputField
              boxStyle="mb-[18px]"
              placeholder="Service Name"
              name="serviceName"
              isRequired
              value={newService?.serviceName}
              onChange={(e: any) => onFormChange(SettingsType.service, e)}
              error={serviceErrors?.ServiceName[0]}
              onBlur={() =>
                onErrorChange(SettingsType.service, "ServiceName", [])
              }
            />

            <TextField
              boxStyle="mb-[18px]"
              placeholder="Description "
              name="description"
              isRequired
              value={newService?.description}
              onChange={(e: any) => onFormChange(SettingsType.service, e)}
              error={serviceErrors?.Description[0]}
              onBlur={() =>
                onErrorChange(SettingsType.service, "Description", [])
              }
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => closeUpdateForm(SettingsType.service)}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                disabled
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DeliverySettings;

// TODO: update delivery location

// TODO: Update convenience fee

// TODO: Update  weight range
