import { stringToNumber } from "core/helpers/generalHelpers";
import { apicall } from "./apicall";

export const getTransportationAnalytics = (query: TransportationQuery) => {
  return apicall({
    endpoint: "/logistics/admin/dashboardStat",
    pQuery: {
      ...query,
    },
    method: "GET",
    auth: true,
  });
};

export const getProfiledAgent = () => {
  return apicall({
    endpoint: "/logistics/getprofiledagent",
    method: "GET",
    auth: true,
  });
};

export const getProfiledDrivers = () => {
  return apicall({
    endpoint: "/logistics/getprofileddrivers",
    method: "GET",
    auth: true,
  });
};

export const addUpdateTransportationAgent = async (
  agent: NewTransportationAgent,
  agentId: string,
) => {
  const data = new FormData();

  data.append("FirstName", agent?.firstName);
  data.append("LastName", agent?.lastName);
  data.append("Email", agent?.email);
  data.append("ContactAddress", agent?.contactAddress);
  data.append("PhoneNumber", agent?.phoneNumber);
  data.append("ApplicationForm", agent?.applicationForm);
  data.append("UserType", `${agent?.userType}`);
  data.append("LocationId", agent?.locationId);

  var isUpdate = agentId?.length > 1;

  return apicall({
    endpoint: isUpdate
      ? "/logistics/updateProfiledAgent"
      : "/logistics/profile-agent",
    param: isUpdate ? agentId : "",
    method: "POST",
    multipart: true,
    auth: true,
    body: data,
  });
};

export const getTripsAssignedToDriver = (agentId: string) => {
  return apicall({
    endpoint: "/Transportation/GetTripsAssignedToDriver",
    param: agentId,
    method: "GET",
    auth: true,
  });
};

export const getAgentDeliveryHistory = (agentId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/Get-AgentDeliveryHistory",
    param: agentId,
    method: "GET",
    auth: true,
  });
};

// DELIVERY SETTINGS
export const addUpdateService = (service: NewService, serviceId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/CreateService",
    method: "POST",
    body: service,
    auth: true,
  });
};

export const addUpdateDeliveryLocation = (
  location: NewDeliveryLocation,
  locationId: string,
) => {
  return apicall({
    endpoint: "/Logistics/Admin/CreateLocation",
    method: "POST",
    body: {
      ...location,
      expressDeliveryCost: stringToNumber(location?.expressDeliveryCost),
      normalDeliveryCost: stringToNumber(location?.normalDeliveryCost),
    },
    auth: true,
  });
};

export const addUpdateDeliveryConvenienceFee = (
  fee: NewConvenienceFee,
  feeId: string,
) => {
  return apicall({
    endpoint: "/Logistics/Admin/CreateConvenienceFee",
    method: "POST",
    body: {
      ...fee,
      amount: stringToNumber(fee?.amount),
    },
    auth: true,
  });
};

export const addUpdateWeightRange = (
  range: NewWeightRange,
  rangeId: string,
) => {
  return apicall({
    endpoint: "/Logistics/Admin/CreateWeightRange",
    method: "POST",
    body: range,
    auth: true,
  });
};

export const getAvailableServices = () => {
  return apicall({
    endpoint: "/Logistics/GetAvailableServices",
    method: "GET",
    auth: true,
  });
};

export const getAvailableLocations = () => {
  return apicall({
    endpoint: "/Logistics/GetAvailableLocations",
    method: "GET",
    auth: true,
  });
};

export const getAvailableWeights = () => {
  return apicall({
    endpoint: "/Logistics/GetAvailableWeights",
    method: "GET",
    auth: true,
  });
};

export const getAvailableConvenienceFees = () => {
  return apicall({
    endpoint: "/Logistics/GetAvailableConvenienceFees",
    method: "GET",
    auth: true,
  });
};

export const deleteService = (serviceId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/DeleteService",
    method: "DELETE",
    param: serviceId,
    auth: true,
  });
};

export const deleteLocation = (locationId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/deleteLocation",
    method: "DELETE",
    param: locationId,
    auth: true,
  });
};

export const deleteWeight = (weightId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/deleteWeight",
    method: "DELETE",
    param: weightId,
    auth: true,
  });
};

export const deleteConvenienceFee = (serviceId: string) => {
  return apicall({
    endpoint: "/Logistics/Admin/DeleteConvenienceFee",
    method: "DELETE",
    param: serviceId,
    auth: true,
  });
};

// RIDE SETTINGS
export const addUpdateBusStops = (busStop: NewBusStop, busStopId: string) => {
  var isUpdate = busStopId?.length > 0;

  return apicall({
    endpoint: isUpdate
      ? "/Transportation/UpdateBusStop"
      : "/Transportation/AddBusStop",
    method: isUpdate ? "PUT" : "POST",
    param: isUpdate ? busStopId : "",
    body: {
      ...busStop,
    },
    auth: true,
  });
};

export const deleteBusStop = (busStopId: string) => {
  return apicall({
    endpoint: "/Transportation/DeleteBusStop",
    method: "DELETE",
    param: busStopId,
    auth: true,
  });
};

export const getAllBusStops = () => {
  return apicall({
    endpoint: "/Transportation/GetAllBusStops",
    method: "GET",
    auth: true,
  });
};

export const getBusStopById = (busStopId: string) => {
  return apicall({
    endpoint: "/Transportation/GetBusStopById",
    param: busStopId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateBusTrip = (trip: NewTrip, tripId: string) => {
  var isUpdate = tripId?.length > 0;

  return apicall({
    endpoint: isUpdate
      ? "/Transportation/UpdateTrip"
      : "/Transportation/addTrip",
    method: isUpdate ? "PUT" : "POST",
    param: isUpdate ? tripId : "",
    body: {
      ...trip,
    },
    auth: true,
  });
};

export const addUpdatePlannedTrip = (
  plannedTrip: NewPlannedTrip,
  plannedTripId: string,
) => {
  var isUpdate = plannedTripId?.length > 0;

  return apicall({
    endpoint: !isUpdate
      ? "/Transportation/PlanTrip"
      : "/Transportation/UpdatePlannedTrip",
    method: isUpdate ? "PUT" : "POST",
    param: isUpdate ? plannedTripId : "",
    body: {
      ...plannedTrip,
    },
    auth: true,
  });
};

export const deleteTrip = (tripId: string) => {
  return apicall({
    endpoint: "/Transportation/DeleteTrip",
    method: "DELETE",
    param: tripId,
    auth: true,
  });
};

export const getAllTrips = () => {
  return apicall({
    endpoint: "/Transportation/GetAllTrips",
    method: "GET",
    auth: true,
  });
};

export const getTripById = (tripId: string) => {
  return apicall({
    endpoint: "/Transportation/GetTripById",
    param: tripId,
    method: "GET",
    auth: true,
  });
};

export const addUpdateTransportFare = (
  fare: NewTransportFare,
  fareId: string,
) => {
  var isUpdate = fareId?.length > 0;

  return apicall({
    endpoint: !isUpdate
      ? "/Transportation/CreateTransportFare"
      : "/Transportation/UpdateTransportFare",
    method: isUpdate ? "PUT" : "POST",
    param: isUpdate ? fareId : "",
    body: {
      ...fare,
      convenienceFee: stringToNumber(fare?.convenienceFee),
      fare: stringToNumber(fare?.fare),
    },
    auth: true,
  });
};

export const deleteTransportFare = (fareId: string) => {
  return apicall({
    endpoint: "/Transportation/DeleteTransportFare",
    method: "DELETE",
    param: fareId,
    auth: true,
  });
};

export const getAllTransportFares = () => {
  return apicall({
    endpoint: "/Transportation/GetAllTransportFares",
    method: "GET",
    auth: true,
  });
};

export const getTransportFareById = (fareId: string) => {
  return apicall({
    endpoint: "/Transportation/getTransportFareById",
    param: fareId,
    method: "GET",
    auth: true,
  });
};

// DELIVERY ORDER
export const getDeliveryOrders = (
  query: DeliveryOrderQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/Logistics/Admin/Get-Orders",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    method: "GET",
    auth: true,
  });
};

export const cancelDelivery = (orderId: string, comment: string) => {
  return apicall({
    endpoint: "/Logistics/admin/Cancel-Request",
    method: "POST",
    body: {
      orderId,
      comment,
    },
    auth: true,
  });
};

export const assignToDispatch = (orderId: string, deliveryAgentId: string) => {
  return apicall({
    endpoint: "/Logistics/Assign-to-Dispatch",
    method: "POST",
    body: {
      orderId,
      deliveryAgentId,
    },
    auth: true,
  });
};

export const getDeliveryOrderItem = (orderId: string) => {
  return apicall({
    endpoint: "/Logistics/GetOrderById",
    param: orderId,
    method: "GET",
    auth: true,
  });
};

// Fleet management
export const addUpdateVehicle = (vehicle: NewVehicle, id: string) => {
  var isUpdate = id?.length > 0;

  if (!isUpdate) {
    const data = new FormData();

    data.append("Colour", vehicle?.colour);
    data.append("Make", vehicle?.make);
    data.append("Picture", vehicle?.picture);
    data.append("RegistrationNumber", vehicle?.registrationNumber);
    data.append("Seats", `${vehicle?.seats}`);
    data.append("VehicleId", vehicle?.vehicleId);
    data.append("VehicleType", vehicle?.vehicleType);

    return apicall({
      endpoint: "/Transportation/addVehicle",
      method: "POST",
      body: data,
      multipart: true,
      auth: true,
    });
  } else {
    return apicall({
      endpoint: "/Transportation/updateVehicle",
      method: "PUT",
      param: id,
      body: {
        ...vehicle,
      },
      auth: true,
    });
  }
};

export const assignDriverToVehicle = (vehicleId: string, driverId: string) => {
  return apicall({
    endpoint: "/Transportation/assignDriverToVehicle",
    method: "POST",
    auth: true,
    body: {
      driverId,
      vehicleId,
    },
  });
};

export const getVehicles = () => {
  return apicall({
    endpoint: "/Transportation/getAllVehicles",
    method: "GET",
    auth: true,
  });
};

export const getVehicleById = (id: string) => {
  return apicall({
    endpoint: "/Transportation/getVehicleById",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const deleteVehicle = (id: string) => {
  return apicall({
    endpoint: "/Transportation/DeleteVehicle",
    method: "DELETE",
    param: id,
    auth: true,
  });
};

export const getPlannedTrips = (
  query: TripQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/transportation/GetPlannedTrips",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    method: "GET",
    auth: true,
  });
};

export const assignDriverToTrip = (plannedTripId: string, driverId: string) => {
  return apicall({
    endpoint: "/Transportation/AssignPlannedTripToDriver",
    method: "POST",
    auth: true,
    body: {
      plannedTripId,
      driverId,
    },
  });
};

export const getDriverTripHistories = (query: TripQuery) => {
  return apicall({
    endpoint: "/transportation/GetDriverTripHistories",
    pQuery: {
      ...query,
    },
    method: "GET",
    auth: true,
  });
};

// NOTIFICATIONS
export const getTransportNotifications = () => {
  return apicall({
    endpoint: "/Logistics/Notification/GetAllNotifications",
    method: "GET",
    auth: true,
  });
};

export const markAllTransportNotificationAsRead = () => {
  return apicall({
    endpoint: "/Logistics/Notification/MarkAll-Read",
    method: "POST",
    auth: true,
  });
};

export const markTransportNotificationAsRead = (notificationId: string) => {
  return apicall({
    endpoint: "/Logistics/Notification/Mark-Read",
    method: "POST",
    body: {
      notificationId,
    },
    auth: true,
  });
};

export const deleteTransportNotification = (notificationId: string) => {
  return apicall({
    endpoint: "/Logistics/Notification/Delete-Notification",
    method: "DELETE",
    param: notificationId,
    auth: true,
  });
};

export const getTransportOrder = (
  query: TransportOrderQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apicall({
    endpoint: "/Transportation/admin/GetTripBookingsByLocation",
    pQuery: {
      ...query,
      pageNumber,
      pageSize,
    },
    method: "GET",
    auth: true,
  });
};

export const getTransportOrderById = (tripId: string) => {
  return apicall({
    endpoint: "/Transportation/admin/GetBookingById",
    param: tripId,
    method: "GET",
    auth: true,
  });
};
