import Accordion from "core/components/Accordion";
import loansImg from "assets/icons/loans.svg";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoanStore from "core/services/stores/useLoanStore";
import { addWhiteIcon } from "core/consts/images";
import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import minusIcon from "assets/icons/minusWhite.svg";
import { actionbtn } from "core/consts/styling";
import { cx } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";

const LoanSettings = () => {
  const locations = useLocationStore((store) => store.locations);
  const loanParameter = useLoanStore((store) => store.loanParameter);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const messageSchedules = useLoanStore((store) => store.messageSchedules);
  const getScheduleAction = useLoanStore((store) => store.getMessageSchedules);
  const addScheduleAction = useLoanStore((store) => store.addMessageSchedules);
  const getLoanParameterAction = useLoanStore(
    (store) => store.getLoanParameter,
  );
  const toggleLoanParameterAction = useLoanStore(
    (store) => store.toggleLoanParameter,
  );

  // Handle schedulers
  const [schedule, setSchedule] = useState<MessageSchedule[]>([]);

  const handleInputChange = (
    messageType: number,
    index: number,
    field: keyof MessageSchedule,
    value: string | number,
  ) => {
    setSchedule((prevSchedule) => {
      // Filter items that match the messageType
      const matchingItems = prevSchedule.filter(
        (msg) => msg.messageType === messageType,
      );

      // Map over matching items and update the specified index
      const updatedMatchingItems = matchingItems.map((msg, i) =>
        i === index ? { ...msg, [field]: value } : msg,
      );

      // Combine updated matching items with non-matching items
      return [
        ...prevSchedule.filter((msg) => msg.messageType !== messageType), // Non-matching items
        ...updatedMatchingItems, // Updated items
      ];
    });
  };
  // Function to add a new message to a specific messageType group
  const addNewMessage = (messageType: -1 | 0 | 1 | any) => {
    setSchedule([
      ...schedule,
      { messageSubject: "", messageBody: "", dayInterval: 0, messageType },
    ]);
  };

  // Function to remove a message by index
  const removeMessage = (messageType: -1 | 0 | 1 | number, index: number) => {
    setSchedule(
      schedule
        .filter((msg) => msg.messageType !== messageType)
        .concat(
          schedule
            .filter((msg) => msg.messageType === messageType)
            .filter((_, i) => i !== index),
        ),
    );
  };

  const validateSchedule = (data: MessageSchedule[]) => {
    var isValid = true;

    if (data?.length > 0) {
      // eslint-disable-next-line array-callback-return
      data?.map((msg) => {
        if (msg?.messageBody?.length < 1) {
          return (isValid = false);
        }

        if (msg?.messageSubject?.length < 1) {
          return (isValid = false);
        }

        if (msg?.dayInterval < 0) {
          return (isValid = false);
        }
      });
    }

    return isValid;
  };

  // Filter schedules based on message type
  const filterByMessageType = (type: -1 | 0 | 1 | any) =>
    schedule.filter((msg) => msg.messageType === type);

  const updateScheduler = async (e: any) => {
    e.preventDefault();

    if (validateSchedule(schedule)) {
      await addScheduleAction(schedule);
    } else {
      notification({
        message:
          "Please pass all required information. Message Subject and Body is required.Days interval must not be greater than zero",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    const fetchData = async () => {
      await getLoanParameterAction();
      await getScheduleAction();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLoanParameterAction, getScheduleAction]);

  useEffect(() => {
    if (messageSchedules) {
      setSchedule([...messageSchedules]);
    }
  }, [messageSchedules]);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex flex-col justify-start lg:flex-row lg:justify-between">
          <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
            <img src={loansImg} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Loan Service Management / Settings
            </h5>
          </div>
          <div className="flex items-center gap-5">
            <Link
              className="text-brand hover:underline"
              to="/superadmin/loan-management"
            >
              Loan Service Management
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/superadmin/loan-management/loan-parameter"
            >
              Loan Parameters
            </Link>
          </div>
        </header>

        <section className="mb-[28px]">
          <Accordion id="reminderSchedule" title="Loan Reminder Schedule">
            <section className="mb-[28px] bg-white px-5 py-10">
              <form onSubmit={updateScheduler}>
                {[-1, 0, 1].map((type) => (
                  <section key={type} className="mb-10">
                    <div className="flex items-center gap-3">
                      <h5 className="text-[14px] font-[500] text-black">
                        {type === -1
                          ? "Before Due Date"
                          : type === 0
                            ? "On Due Date"
                            : "After Due Date"}
                      </h5>

                      <button
                        type="button"
                        className="flex h-[26px] w-[26px] items-center justify-center rounded-[3px] bg-brand"
                        onClick={() => addNewMessage(type)}
                      >
                        <img
                          src={addWhiteIcon}
                          loading="lazy"
                          className="h-[14px] w-[14px]"
                          alt="add message"
                        />
                      </button>
                    </div>

                    <div className="my-5">
                      {filterByMessageType(type).length > 0 ? (
                        filterByMessageType(type).map((msg, index) => (
                          <div key={index} className="mb-5">
                            <div className="mb-3 flex flex-col items-center justify-between gap-5 lg:flex-row">
                              <InputField
                                boxStyle="mb-0 w-full lg:w-1/2"
                                label="Message Subject"
                                placeholder="Message Subject"
                                name={`messageSubject-${index}`}
                                value={msg?.messageSubject}
                                onChange={(e: any) =>
                                  handleInputChange(
                                    type,
                                    index,
                                    "messageSubject",
                                    e.target.value,
                                  )
                                }
                              />

                              <InputField
                                boxStyle="mb-0 w-full lg:w-1/2"
                                label="Day Interval"
                                type="number"
                                placeholder="Day Interval"
                                name={`dayInterval-${index}`}
                                value={msg.dayInterval}
                                onChange={(e: any) =>
                                  handleInputChange(
                                    type,
                                    index,
                                    "dayInterval",
                                    +e.target.value,
                                  )
                                }
                              />
                            </div>

                            <div className="flex items-center justify-between gap-5">
                              <TextField
                                boxStyle="w-full"
                                label="Message Body"
                                name={`messageBody-${index}`}
                                value={msg?.messageBody}
                                onChange={(e: any) =>
                                  handleInputChange(
                                    type,
                                    index,
                                    "messageBody",
                                    e.target.value,
                                  )
                                }
                                instruction="Please use the following placeholders:[CUSTOMER_NAME], [LOAN_BALANCE], [LOAN_DUE_DATE], [DEFAULT_INTEREST_RATE]"
                                rows={1}
                              />

                              <div className="flex items-center gap-2">
                                <button
                                  type="button"
                                  className="flex h-[32px] w-[32px] items-center justify-center rounded-[3px] bg-red-500"
                                  onClick={() => removeMessage(type, index)}
                                >
                                  <img
                                    src={minusIcon}
                                    loading="lazy"
                                    className="h-[16px] w-[16px]"
                                    alt="remove message"
                                  />
                                </button>

                                <button
                                  type="button"
                                  className="flex h-[32px] w-[32px] items-center justify-center rounded-[3px] bg-brand"
                                  onClick={() => addNewMessage(type)}
                                >
                                  <img
                                    src={addWhiteIcon}
                                    loading="lazy"
                                    className="h-[16px] w-[16px]"
                                    alt="remove message"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No reminders</p>
                      )}
                    </div>
                  </section>
                ))}

                <div className="flex w-full gap-3 md:w-2/3 lg:w-1/3">
                  <button
                    className={cx(
                      actionbtn,
                      "w-full rounded-[3px] !bg-brand !px-[26px] !py-3 !text-white",
                    )}
                  >
                    Update Scheduler
                  </button>
                </div>
              </form>
            </section>
          </Accordion>
        </section>

        <section className="mb-[28px]">
          <Accordion
            id="functionalityManagement"
            title="Functionality Management"
          >
            <section className="mb-[28px] bg-white p-5">
              <div className="mb-[16px] flex items-center justify-between gap-2 font-bold">
                <div className="w-1/12">
                  <p>On</p>
                </div>
                <div className="w-1/12">
                  <p>Off</p>
                </div>
                <div className="w-10/12"></div>
              </div>

              <div className="flex items-center justify-between gap-2">
                <div className="w-1/12">
                  <input
                    type="radio"
                    value="true"
                    className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                    checked={loanParameter?.status}
                    onChange={(e) =>
                      toggleLoanParameterAction(loanParameter?.id!, true)
                    }
                  />
                </div>
                <div className="w-1/12">
                  <input
                    type="radio"
                    value="false"
                    className="border-1 h-[25px] w-[25px] !border-brand !bg-brand text-brand !accent-[#DFA1F4] focus:border-brand focus:bg-brand"
                    checked={!loanParameter?.status}
                    onChange={(e) =>
                      toggleLoanParameterAction(loanParameter?.id!, false)
                    }
                  />
                </div>
                <div className="w-10/12">
                  <p className="h-[25px]">{loanParameter?.loanTitle}</p>
                </div>
              </div>
            </section>
          </Accordion>
        </section>
      </div>
    </>
  );
};

export default LoanSettings;
