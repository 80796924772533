import {
  LOAN_STATUS_LIST,
  ORDER_STATUS,
  PROCESS_INSPECTION_REQUEST,
  PROCESS_ORDER,
  REPAYMENT_STATUS,
} from "core/consts/systemConst";
import moment from "moment-timezone";

export const isNumeric = (str: string) => {
  return /^\d+$/.test(str);
};

export const numbersOnly = (e: any) => {
  if (isNaN(e?.key) && e?.key !== "Backspace") {
    e.preventDefault();
  }
};

export const isEmail = (input: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};

export const formatSimpleDate = (date: any, isDash = false) => {
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return null;

  var formattedDate = "";
  if (!isDash) {
    formattedDate = `${appendLeadZero(d.getDate())}/${appendLeadZero(
      d.getMonth() + 1,
    )}/${d.getFullYear()}`;
  } else {
    formattedDate = `${appendLeadZero(d.getDate())}-${appendLeadZero(
      d.getMonth() + 1,
    )}-${d.getFullYear()}`;
  }
  return formattedDate;
};

export const formatToFormDate = (date: any) => {
  if (date == null || date?.length < 1) return "";
  const d = new Date(date);

  var formattedDate = `${d.getFullYear()}-${appendLeadZero(
    d.getMonth() + 1,
  )}-${appendLeadZero(d.getDate())}`;

  return formattedDate;
};

export const timeFormat12Hour = (date: any) => {
  let h = date.getHours();
  let m = date.getMinutes();
  let ampm = h >= 12 ? "pm" : "am";

  h = h % 12; //reminder
  h = h ? h : 12;

  m = m.toString().padStart(2, "0");
  const formatedTimeString = h + ":" + m + " " + ampm;
  return formatedTimeString;
};

export const convertTimeTo24Hour = (timeIn12hrFormat: string) => {
  let [time, period]: any = timeIn12hrFormat.split(" ");
  let [hours, minutes] = time.split(":");

  hours = parseInt(hours);

  if (period === "AM") {
    if (hours === 12) {
      hours = 0; // Midnight case
    }
  } else if (period === "PM") {
    if (hours !== 12) {
      hours += 12; // Afternoon/evening case
    }
  }

  hours = hours.toString().padStart(2, "0");
  minutes = minutes.padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const convertTimeTo12Hour = (time: string) => {
  let [hours, minutes]: any = time.split(":");
  hours = parseInt(hours);

  let period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes.padStart(2, "0");
  return `${hours}:${minutes} ${period}`;
};

const appendLeadZero = (val: any) => (Number(val) > 9 ? val : `0${val}`);

export const formatCurrency = (value: any) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.0;
    return val === 0 ? "₦ 0.00" : `₦ ${Number(val).toLocaleString("en-US")}`;
  }

  return "₦ 0.00";
};

export const formatNumber = (value: any) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.0;
    return val === 0 ? "0" : `${Number(val).toLocaleString("en-US")}`;
  }

  return "0";
};

export const getDataFromSession = (name: string) => {
  try {
    const data: any = sessionStorage.getItem(name);
    if (
      data !== null &&
      data !== undefined &&
      typeof JSON.parse(data) === "object" &&
      !Array.isArray(data)
    ) {
      return JSON.parse(data);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const isToday = (date: any) => {
  const theDate = new Date(date);
  const today = new Date();
  return today.setHours(0, 0, 0, 0) === theDate.setHours(0, 0, 0, 0);
};

/**
 * recieve a date-time string and return date
 * @param {String} dateString
 * @returns {String} Format: Tues, 24 Sept 2019
 */
export const getDate = (dateString: any, showYear = true) => {
  const date = new Date(dateString);

  if (showYear) {
    return `${
      MONTHS[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  } else {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
  }
};

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getTimes = () => {
  const times = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 13; i++) {
    times.push({ name: `${i}:00 AM` });
    times.push({ name: `${i}:00 PM` });
    times.push({ name: `${i}:25 AM` });
    times.push({ name: `${i}:25 PM` });
    times.push({ name: `${i}:30 AM` });
    times.push({ name: `${i}:30 PM` });
    times.push({ name: `${i}:45 AM` });
    times.push({ name: `${i}:45 PM` });
  }

  return times;
};

export const getRandomDarkColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getRandomLightBackgroundColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  let isDarkEnough = false;

  while (!isDarkEnough) {
    color = "#";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    const luminance =
      0.299 * parseInt(color.substr(1, 2), 16) +
      0.587 * parseInt(color.substr(3, 2), 16) +
      0.114 * parseInt(color.substr(5, 2), 16);
    isDarkEnough = luminance < 180; // Adjust this threshold as needed
  }

  return color;
};

export const addSpaceToCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, " $1").trim();

export const stringToNumber = (word: string | number) => {
  var res = +word;

  if (isNaN(res)) return 0;

  return res;
};

export const isObjectEmpty = (value: any) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  return Object.keys(value).length === 0;
};

export const isValidPassword = (val: string) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{9,}$/.test(val);
};

export const isValidFormDate = (val: string) => {
  return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val);
};

export const getBusinessAnalytics = (data: any) => {
  const aggregatedData: any = {
    createdAt: [],
    transactions: {
      successCount: [],
      successAmount: [],
      failedCount: [],
      failedAmount: [],
    },
    signups: {
      male: [],
      female: [],
    },
    revenue: {
      successCount: [],
      successAmount: [],
      failedCount: [],
      failedAmount: [],
    },
    delivery: {
      successCount: [],
      successAmount: [],
      failedCount: [],
      failedAmount: [],
    },
  };

  for (const day of data) {
    aggregatedData.createdAt.push(getDate(day?.createdAt, false));

    for (const key in day.transactions) {
      aggregatedData.transactions[key].push(day?.transactions[key]);
    }

    for (const key in day.signups) {
      aggregatedData.signups[key].push(day.signups[key]);
    }

    for (const key in day.revenue) {
      aggregatedData.revenue[key].push(day.revenue[key]);
    }

    for (const key in day.delivery) {
      aggregatedData.delivery[key].push(day.delivery[key]);
    }
  }

  return aggregatedData;
};

export const formatLineChartData = (
  data: EventAnalyticData | null,
): EventGraphData => {
  if (!data || data == null || data?.eventAnalytics?.length < 1) {
    return {
      female: [],
      male: [],
      date: [],
    };
  }

  const formattedData: EventGraphData = {
    female: [],
    male: [],
    date: [],
  };

  data?.eventAnalytics?.forEach((entry) => {
    formattedData.female.push(entry.femaleCount!);
    formattedData.male.push(entry.maleCount);
    formattedData.date.push(new Date(entry.date).toLocaleDateString()); // Format date as needed
  });

  return formattedData;
};

export const capitalizeFirstWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitAndCapitalize = (word: string) => {
  // Using regex to split at camelCase boundaries
  const words = word.split(/([A-Z][a-z]+)/);

  // Capitalize the first letter of each word
  words[0] = capitalizeFirstWord(words[0]);

  words.forEach((word, index) => {
    if (index > 0) {
      words[index] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  // Join the words back into a string
  return words.join(" ");
};

export const getDateTime = (date: string) => {
  if (!date) return null;

  if (date.match(/\d{2}\/\d{2}\/\d{4}/)) {
    return moment
      .tz(date, "MM/DD/YYYY", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+/)) {
    return moment
      .tz(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  if (date.match(/\d{4}-\d{2}-\d{2}/)) {
    return moment
      .tz(date, "YYYY-MM-DD", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  return null;
};

export const getProcessStatus = (status: ManageOrder) => {
  var res = ORDER_STATUS.pending;

  switch (status) {
    case PROCESS_ORDER.accept:
      res = ORDER_STATUS.processing;
      break;
    case PROCESS_ORDER.cancel:
      res = ORDER_STATUS.cancelled;
      break;
    case PROCESS_ORDER.delivered:
      res = ORDER_STATUS.delivered;
      break;
    default:
      break;
  }

  return res;
};

export const getStatusBackgroundColor = (status: string) => {
  switch (status.toLowerCase()) {
    case ORDER_STATUS.pending?.toLowerCase():
    case PROCESS_INSPECTION_REQUEST.pending?.toLowerCase():
      return "!bg-white";
    case PROCESS_INSPECTION_REQUEST.accepted?.toLowerCase():
    case ORDER_STATUS.processing?.toLowerCase():
      return "!bg-[#f3d191]";
    case ORDER_STATUS.cancelled?.toLowerCase():
    case ORDER_STATUS.failed?.toLowerCase():
    case PROCESS_INSPECTION_REQUEST.declined?.toLowerCase():
      return "!bg-[#ff6e6e] !text-white";
    case PROCESS_INSPECTION_REQUEST.fulfilled?.toLowerCase():
    case ORDER_STATUS.delivered?.toLowerCase():
      return "!bg-[#b8f3b6] !text-black-900";
    case ORDER_STATUS.awaiting_payment?.toLowerCase():
      return "!bg-[#87CEEB] !text-black";
    default:
      return "!bg-white";
  }
};

export const convertImgToBase64 = (inputFile: any) => {
  if (inputFile === undefined) return "";

  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

/*
export const getBase64ImageFromUrl = async (imageUrl) => {
  const res = await fetch(imageUrl);
  logger(res);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });

  export const previewImage = (fieldId, previewBoxId) => {
    const previewBox = document.getElementById(previewBoxId);
    while (previewBox.firstChild) previewBox.removeChild(previewBox.firstChild);
  
    const { files } = document.getElementById(fieldId);
    const img = document.createElement('img');
  
    if (files[0] !== undefined) {
      img.src = URL.createObjectURL(files[0]);
      previewBox.appendChild(img);
    }
  };
};*/

export const adjustTripAccordionHeight = (
  index: number,
  isIncrement = true,
) => {
  const heightOfField: any = document?.getElementById(`terminalstop${index}`)
    ?.offsetHeight;

  const addTerminalHeight: any =
    document.getElementById("addTerminal")?.offsetHeight;

  const submitTripHeight: any =
    document.getElementById("submitTrip")?.offsetHeight;

  const accordion: any = document.getElementById("busTrips");

  if (isIncrement) {
    accordion.style.height = `${
      accordion?.scrollHeight +
      13 +
      (heightOfField + addTerminalHeight + submitTripHeight)
    }px`;
  } else {
    accordion.style.height = `${
      accordion?.scrollHeight -
      13 -
      (heightOfField + addTerminalHeight + submitTripHeight)
    }px`;
  }
};

export const clearSessionAndLogout = async () => {
  localStorage.clear();
  sessionStorage.clear();
  //isExpiredSession && localStorage.setItem('se', true);
  window.location.assign("/");
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const getMonthStartEnd = (monthsBack: number = 0) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() - monthsBack;

  // Calculate the year and month for the desired offset
  const targetDate = new Date(year, month, 1);
  const targetYear = targetDate.getFullYear();
  const targetMonth = targetDate.getMonth();

  // Calculate the first and last day of the target month
  const firstDay = new Date(targetYear, targetMonth, 1);
  const lastDay = new Date(targetYear, targetMonth + 1, 0);

  return { start: formatToFormDate(firstDay), end: formatToFormDate(lastDay) };
};

export const getLoanStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case LOAN_STATUS_LIST.Pending?.toLowerCase():
    case REPAYMENT_STATUS.Pending?.toLowerCase():
      return "border border-yellow-500 bg-yellow-200 text-black py-[6px] rounded-[50px] px-[16px]";
    case LOAN_STATUS_LIST.Declined?.toLowerCase():
      return "border border-red-500 bg-red-200 text-black py-[6px] rounded-[50px] px-[16px]";
    case LOAN_STATUS_LIST.Active?.toLowerCase():
    case LOAN_STATUS_LIST.Closed?.toLowerCase():
    case REPAYMENT_STATUS.Paid?.toLowerCase():
      return "border border-green-500 bg-green-200 text-black py-[6px] rounded-[50px] px-[16px]";
    default:
      return "border border-gray-500 bg-gray-200 text-black py-[6px] rounded-[50px] px-[16px]";
  }
};

export const cx = (...classNames: string[]) =>
  classNames.filter(Boolean).join(" ");

export const downloadImage = async (url: string, filename: string) => {
  try {
    // Fetch the image and convert it to a blob
    const response = await fetch(url, { mode: "cors" });
    const blob = await response.blob();

    // Create a link element
    const a = document.createElement("a");
    const urlBlob = URL.createObjectURL(blob); // Create a temporary URL for the blob
    a.href = urlBlob;
    a.download = filename; // Set the desired download file name
    document.body.appendChild(a); // Append the link to the body
    a.click(); // Programmatically click the link to trigger download
    document.body.removeChild(a); // Remove the link from the DOM

    // Release the object URL
    URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error("Image download failed:", error);
  }
};
