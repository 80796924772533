import Modal from "core/components/Modal";
import Pagination from "core/components/Pagination";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { activeModule, downloadImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import { getDateTime } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ManageOrders = () => {
  // need state
  const navigate = useNavigate();
  const user = useUserStore((store) => store.user);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const agents = useTransportationStore((store) => store.deliveryAgents);
  const deliveryList = useTransportationStore(
    (store) => store.deliveryOrderList,
  );

  // actions
  const getAgentsAction = useTransportationStore(
    (store) => store.getDeliveryAgents,
  );

  const getOrdersAction = useTransportationStore(
    (store) => store.getDeliveryOrder,
  );

  const assignDeliveryAction = useTransportationStore(
    (store) => store.assignDelivery,
  );

  const [query, setQuery] = useState<DeliveryOrderQuery>({
    search: "",
    status: "",
    startDate: "",
    endDate: "",
    locationFilter: !isSuperAdmin ? user?.location : "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const search = (e: any) => {
    e.preventDefault();
    getOrdersAction(query, deliveryList?.pageNumber, deliveryList?.pageSize);
  };

  const fetchMore = async (page: number) => {
    await getOrdersAction(query, page, deliveryList?.pageSize);
  };

  // order management
  const [selectedOrder, setSelectedOrder] = useState<DeliveryOrder | null>(
    null,
  );
  const [assignModal, setAssignModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");

  const openAssignOrderModal = (order: DeliveryOrder) => {
    setSelectedOrder(order);
    setAssignModal(true);
  };

  const assignToDispatch = async (e: any) => {
    e.preventDefault();

    if (selectedAgent?.length > 0) {
      var res = await assignDeliveryAction(
        selectedOrder?.orderId!,
        selectedAgent!,
      );

      if (res?.isSuccessful) {
        setSelectedAgent("");
        setSelectedOrder(null);
        setAssignModal(false);
      }
    } else {
      notification({
        message: "Please choose a delivery agent",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    agents?.length < 1 && getAgentsAction();

    getOrdersAction(query, deliveryList?.pageNumber, deliveryList?.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add filter by status

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">Manage Orders</h5>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              value={query?.search}
              onChange={onQueryChange}
            />

            <SelectField
              label="Select Location"
              boxStyle="min-w-[200px]"
              selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              defaultName="Select your Location"
              defaultValue=""
              disabled={!isSuperAdmin}
              name="locationFilter"
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={onQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3  px-8 text-white">
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled={
                  deliveryList == null || deliveryList?.orders?.length < 1
                }
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  exportToCSV(deliveryList?.orders, "Delivery Orders");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>
        <section className="mb-[28px]">
          <Table
            headers={[
              "Customer Name",
              "Pick-up Address",
              "Pick-up Contact",
              "Pick-up Date",
              "Assigned Dispatch",
              "Status",
              "Action",
            ]}
          >
            {deliveryList?.orders?.length > 0 ? (
              deliveryList?.orders?.map((order) => (
                <tr key={order?.orderId}>
                  <Row value={order?.customerName} />
                  <Row value={order?.pickUpAddress} />
                  <Row value={order?.contactPhoneNumber} />
                  <Row value={getDateTime(order?.pickUpDate)} />
                  <Row value={order?.assignedDispatch} />
                  <Row value={order?.orderStatus} />
                  <ActionRow style={`flex items-center justify-center gap-3`}>
                    <button
                      className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                      onClick={() => {
                        navigate(
                          `/transportation/manage-orders/${order?.orderId}`,
                        );
                      }}
                    >
                      View
                    </button>
                    {!isSuperAdmin &&
                      order?.orderStatus !== "Cancelled" &&
                      order?.orderStatus !== "Delivered" &&
                      order?.orderStatus !== "Shipped" && (
                        <button
                          onClick={() => openAssignOrderModal(order)}
                          disabled={
                            order?.orderStatus === "Cancelled" ||
                            order?.orderStatus === "Delivered" ||
                            order?.orderStatus === "Shipped"
                          }
                          className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                        >
                          Assign to Dispatch
                        </button>
                      )}
                  </ActionRow>
                </tr>
              ))
            ) : (
              <tr>
                <Row colSpan={7} value="No delivery order yet" />
              </tr>
            )}
          </Table>

          <Pagination
            pageNumber={deliveryList?.pageNumber}
            pageSize={deliveryList?.pageSize}
            totalCount={deliveryList?.totalCount}
            totalPage={deliveryList?.totalPage}
            onFetch={fetchMore}
          />
        </section>
      </div>

      {assignModal && (
        <Modal
          header="Assign Order to Delivery Agent"
          onClose={() => {
            setSelectedAgent("");
            setSelectedOrder(null);
            setAssignModal(false);
          }}
        >
          <form onSubmit={assignToDispatch}>
            <SelectField
              boxStyle="mb-[18px]"
              label="Select Delivery Agent"
              name="selectedAgent"
              defaultName="Select Delivery Agent"
              defaultValue=""
              options={
                agents?.length > 0
                  ? [
                      ...agents?.map((agent) => ({
                        name: agent?.fullName,
                        value: agent?.agentId,
                      })),
                    ]
                  : []
              }
              value={selectedAgent}
              onChange={(e: any) => setSelectedAgent(e?.target?.value)}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setSelectedAgent("");
                  setSelectedOrder(null);
                  setAssignModal(false);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Assign
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ManageOrders;
