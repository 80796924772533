import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import Modal from "core/components/Modal";
import { btn } from "core/consts/styling";
import { cx } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import { useState } from "react";

export default function SmsEngagement() {
  const query = useAnalyticStore((store) => store.analyticsQuery);
  const sendNotification = useAnalyticStore(
    (store) => store.sendSmsNotification,
  );
  const sendPreviewNotification = useAnalyticStore(
    (store) => store.sendSmsPreview,
  );
  const estimates = useAnalyticStore(
    (store) => store.eventAnalytics.estimatedTargetUsers,
  );
  const totalEstimates =
    estimates?.reduce((total, user) => total + user.count, 0) || 0;

  const [data, setData] = useState<EventMessage>({
    messageBody: "",
    messageTitle: "",
  });
  const [errors, setErrors] = useState({
    MessageBody: [],
    MessageTitle: [],
  });

  const [isPreview, setIsPreview] = useState("true");
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([""]);
  const [showPreview, setShowPreview] = useState(false);

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addNewPhoneNumber = () => {
    setPhoneNumbers((state) => [...state, ""]);
  };

  const removePhoneNumber = (index: number) => {
    const data = [...phoneNumbers];
    data.splice(index, 1);
    setPhoneNumbers(data);
  };

  const onPhoneNumberChange = (e: any, index: number) => {
    const { value }: any = e?.target;
    const data: string[] = [...phoneNumbers];
    data[index] = value;
    setPhoneNumbers(data);
  };

  const validatePreview = (message: string, previewNumbers: string[]) => {
    var isValid = true;

    if (message?.length < 1) {
      isValid = false;
      onErrorChange("MessageBody", ["Message Body is required"]);
    }

    if (previewNumbers?.length < 1) {
      notification({
        message: "Please provide at least one phone number",
        type: "danger",
      });
    } else {
      const validationErrors = previewNumbers.map((phone) =>
        /^\d{11}$/.test(phone) ? "" : "Phone number must be 11 digits.",
      );

      isValid = validationErrors.every((error) => error === "");

      if (!isValid) {
        notification({
          message: "All provided Phone numbers must be valid",
          type: "danger",
        });
      }
    }

    return isValid;
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: EventMessage) => {
    var isValid = true;

    if (data?.messageBody?.length < 1) {
      isValid = false;
      onErrorChange("MessageBody", ["Message Body is required"]);
    }

    return isValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (totalEstimates < 1) {
      notification({
        type: "warning",
        message:
          "Estimated audience is currently 0. Please change filter and try again",
      });
      return;
    }

    if (isPreview === "true") {
      onSendPreview(e);
    } else {
      viewLivePreview();
    }
  };

  const viewLivePreview = async () => {
    if (validation(data)) {
      setShowPreview(true);
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const onFinalSend = async () => {
    var res = await sendNotification({
      analyticQuery: query,
      messageBody: data?.messageBody,
      messageTitle: data?.messageTitle,
    });

    if (res?.isSuccessful) {
      setData({
        messageBody: "",
        messageTitle: "",
      });
    } else {
      setErrors((state) => ({
        ...state,
        ...res?.data,
      }));
    }
  };

  const onSendPreview = async (e: any) => {
    if (validatePreview(data?.messageBody, phoneNumbers)) {
      var res = await sendPreviewNotification({
        messageBody: data?.messageBody,
        phoneNumber: phoneNumbers,
      });

      if (!res?.isSuccessful) {
        setErrors((state) => ({
          ...state,
          ...res?.data,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className="mt-5">
        <form onSubmit={onSubmit}>
          <TextField
            boxStyle="mb-[18px]"
            label=""
            placeholder="Your message"
            name="messageBody"
            value={data?.messageBody}
            onChange={(e: any) => onFormChange(e)}
            rows={8}
            error={errors?.MessageBody[0]}
            onBlur={() => onErrorChange("MessageBody", [])}
          />

          <div className="mb-[16px] flex items-center justify-between">
            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isPreview === "true"}
                onChange={() => {
                  setIsPreview("true");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Preview Mode
              </label>
            </fieldset>

            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isPreview === "false"}
                onChange={() => {
                  setIsPreview("false");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Live Mode
              </label>
            </fieldset>
          </div>

          {isPreview === "true" && (
            <>
              {phoneNumbers?.length > 0 &&
                phoneNumbers?.map((doc, index: number) => (
                  <div
                    key={index}
                    className="item-center mb-[18px] flex items-center justify-between gap-3"
                  >
                    <InputField
                      boxStyle="w-2/3"
                      inputStyle="!mb-0"
                      label={`${index + 1}. Phone Number`}
                      placeholder=""
                      name={`phoneNumbers-${index}`}
                      value={doc}
                      isNumberOnly
                      onChange={(e: any) => onPhoneNumberChange(e, index)}
                    />

                    <div className="mt-6 flex w-1/3 items-center gap-3">
                      <button
                        type="button"
                        className={`${btn} !w-1/2 border !border-[#6C18A4] !bg-[#DFA1F4] !text-black`}
                        onClick={() => addNewPhoneNumber()}
                      >
                        Add
                      </button>

                      {phoneNumbers?.length > 1 && (
                        <button
                          type="button"
                          className={`${btn} !w-1/2 border !border-red-500 !bg-red-200 !text-black`}
                          onClick={() => removePhoneNumber(index)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              <button
                className={cx(btn, "mt-8 w-full bg-yellow-500 text-white")}
              >
                Send Preview
              </button>
            </>
          )}

          {isPreview === "false" && (
            <button className={cx(btn, "mt-8 w-full bg-brand text-white")}>
              Send Message
            </button>
          )}
        </form>
      </div>
      {showPreview && (
        <Modal
          header={`SMS Live Mode`}
          instruction="Review and confirm the message before proceeding to send"
          bodyStyle="lg:!w-[60%]"
          onClose={() => {
            setShowPreview(false);
          }}
        >
          <div className="flex h-[300px] items-center justify-center overflow-y-auto rounded-md border p-3 text-center">
            <p>{data?.messageBody}</p>
          </div>

          <div className="mt-5 flex flex-col-reverse gap-3 md:flex-row">
            <button
              type="button"
              onClick={() => {
                setShowPreview(false);
              }}
              className={cx(btn, "w-full bg-[#F2EAF8] !px-[26px] !py-3")}
            >
              Continue Edit
            </button>
            <button
              onClick={() => onFinalSend()}
              className={cx(btn, "w-full bg-[#DFA1F4] !px-[26px] !py-3")}
            >
              Send SMS
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
