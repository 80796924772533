import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  getCustomerData,
  getEventAnalytics,
  sendEmailNotification,
  sendFireBaseNotification,
  sendPreviewEmailNotification,
  sendPreviewSmsNotification,
  sendSmsNotification,
  uploadFile,
} from "../api/analytics";
import {
  formatLineChartData,
  getMonthStartEnd,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import { generateEmailTemplate } from "core/helpers/mailHelpers";

type State = {
  isLoading: boolean;
  customerDataList: CustomerDataList;
  customerData: CustomerData[];
  eventGraphData: EventGraphData;
  analyticsQuery: EventAnalyticsQuery;
  eventAnalytics: EventAnalyticData;
};

type Action = {
  getCustomerDataList: (query: UserDataQueryParam) => Promise<void>;
  getEventAnalytics: (query: EventAnalyticsQuery) => Promise<void>;
  sendFirebaseNotification: (
    data: EventMessageData,
    saveNotification: boolean,
  ) => Promise<UIResponse>;
  sendSmsNotification: (data: EventMessageData) => Promise<UIResponse>;
  sendSmsPreview: (data: SmsPreview) => Promise<UIResponse>;
  sendEmailNotification: (
    data: EventMessageData,
    sections: DraggableSection[],
  ) => Promise<UIResponse>;
  sendEmailPreview: (
    data: EmailPreview,
    sections: DraggableSection[],
  ) => Promise<UIResponse>;
  reset: () => void;
};

type AnalyticsState = State & Action;

const monthStartAndEnd = getMonthStartEnd(1);

const initialState: State = {
  isLoading: false,
  analyticsQuery: {
    locationId: [],
    startDate: monthStartAndEnd.start,
    endDate: monthStartAndEnd.end,
    event: 0,
  },
  eventGraphData: {
    date: [],
    female: [],
    male: [],
  },
  customerDataList: {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    totalPage: 1,
  },
  eventAnalytics: {
    estimatedTargetUsers: [],
    eventAnalytics: [],
  },
  customerData: [],
};

const useAnalyticStore = create<AnalyticsState>()(
  devtools(
    persist(
      (set, get): AnalyticsState => ({
        ...initialState,
        getCustomerDataList: async (query) => {
          set({ isLoading: true });
          var res: UIResponse = await getCustomerData(query);

          if (res?.isSuccessful) {
            set({ customerData: res?.data?.data });
          }

          set({ isLoading: false });
          return;
        },
        getEventAnalytics: async (query) => {
          set({ isLoading: true, analyticsQuery: query });
          var res: UIResponse = await getEventAnalytics(query);

          if (res?.isSuccessful) {
            set({
              eventAnalytics: res?.data?.data,
              eventGraphData: formatLineChartData(res?.data?.data),
            });
          } else {
            set({
              eventAnalytics: { estimatedTargetUsers: [], eventAnalytics: [] },
              eventGraphData: formatLineChartData(null),
            });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return;
        },
        sendFirebaseNotification: async (data, saveNotification) => {
          set({ isLoading: true });
          var res: UIResponse = await sendFireBaseNotification(
            data,
            saveNotification,
          );

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
          set({ isLoading: false });
          return res;
        },
        sendSmsNotification: async (data) => {
          set({ isLoading: true });
          var res: UIResponse = await sendSmsNotification(data);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
          set({ isLoading: false });
          return res;
        },
        sendSmsPreview: async (data) => {
          set({ isLoading: true });
          var res: UIResponse = await sendPreviewSmsNotification(data);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
          set({ isLoading: false });
          return res;
        },
        sendEmailNotification: async (data, sections) => {
          set({ isLoading: true });

          const imageSections = sections.filter(
            (section) => section.type === "image" && section.imageFile,
          );

          const images = imageSections.map(
            (section) => section.imageFile as File,
          );

          const uploadRes = await uploadFile(images);

          if (uploadRes?.data?.data?.length === imageSections.length) {
            imageSections.forEach((section, index) => {
              section.content = uploadRes?.data?.data[index];
            });
          } else {
            console.error("Mismatch between uploaded data and image sections.");
          }

          data.messageBody = generateEmailTemplate({
            title: data?.messageTitle,
            sections,
            isServer: true,
          });

          const res: UIResponse = await sendEmailNotification(data);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        sendEmailPreview: async (data, sections) => {
          set({ isLoading: true });

          const imageSections = sections.filter(
            (section) => section.type === "image" && section.imageFile,
          );

          const images = imageSections.map(
            (section) => section.imageFile as File,
          );

          const uploadRes = await uploadFile(images);

          if (uploadRes?.data?.data?.length === imageSections.length) {
            imageSections.forEach((section, index) => {
              section.content = uploadRes?.data?.data[index];
            });
          } else {
            console.error("Mismatch between uploaded data and image sections.");
          }

          data.messageBody = generateEmailTemplate({
            title: data?.messageTitle,
            sections,
            isServer: true,
          });

          const res: UIResponse = await sendPreviewEmailNotification(data);

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        reset: () => {
          set({
            ...initialState,
          });
        },
      }),
      {
        name: "analyticStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAnalyticStore;
