import InputField from "core/components/formfields/InputField";
import { btn } from "core/consts/styling";
import { cx, isEmail } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import { useState } from "react";
import Button from "core/components/Button";
import textIcon from "assets/img/textfield.png";
import imageIcon from "assets/img/imageField.png";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableField } from "core/components/DraggableField";
import { generateEmailTemplate } from "core/helpers/mailHelpers";
import Modal from "core/components/Modal";

export default function EmailEngagement() {
  const query = useAnalyticStore((store) => store.analyticsQuery);
  const sendNotification = useAnalyticStore(
    (store) => store.sendEmailNotification,
  );
  const sendPreviewNotification = useAnalyticStore(
    (store) => store.sendEmailPreview,
  );
  const estimates = useAnalyticStore(
    (store) => store.eventAnalytics.estimatedTargetUsers,
  );
  const totalEstimates =
    estimates?.reduce((total, user) => total + user.count, 0) || 0;

  const [data, setData] = useState<EventMessage>({
    messageBody: "",
    messageTitle: "",
  });
  const [errors, setErrors] = useState({
    MessageBody: [],
    MessageTitle: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const [sections, setSections] = useState<DraggableSection[]>([]);
  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState("true");
  const [emails, setEmails] = useState<string[]>([""]);

  const { v4: uuidv4 } = require("uuid");

  const addSection = (type: DraggableSection["type"]) => {
    setSections([
      ...sections,
      {
        name: uuidv4(),
        type,
        content: "",
        imageFile: undefined,
      },
    ]);
  };

  const moveSection = (dragIndex: number, hoverIndex: number) => {
    const updatedSections = [...sections];
    const [removed] = updatedSections.splice(dragIndex, 1);
    updatedSections.splice(hoverIndex, 0, removed);
    setSections(updatedSections);
  };

  const updateSection = (name: string, content: string, imageFile?: File) => {
    setSections(
      sections.map((section) =>
        section?.name === name ? { ...section, content, imageFile } : section,
      ),
    );
  };

  const removeSection = (name: string) => {
    setSections(sections.filter((section) => section.name !== name));
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const addNewEmail = () => {
    setEmails((state) => [...state, ""]);
  };

  const removeEmail = (index: number) => {
    const data = [...emails];
    data.splice(index, 1);
    setEmails(data);
  };

  const onEmailChange = (e: any, index: number) => {
    const { value }: any = e?.target;
    const data: string[] = [...emails];
    data[index] = value;
    setEmails(data);
  };

  const validation = () => {
    var isValid = true;

    if (totalEstimates < 1) {
      notification({
        type: "warning",
        message:
          "Estimated audience is currently 0. Please change filter and try again",
      });
      return;
    }

    if (data?.messageTitle?.length < 1) {
      isValid = false;
      onErrorChange("MessageTitle", ["Campaign Title is required"]);
    }

    if (sections?.length < 1) {
      notification({
        message: "Please add a section",
        type: "warning",
      });

      return;
    }

    return isValid;
  };

  const validateSections = () => {
    let isValid = true;
    const errorsMap: Record<number, string> = {};

    sections.forEach((section, index) => {
      let error = "";

      if (section.type === "text" && section.content.trim().length === 0) {
        error = "Text content is required.";
        isValid = false;
      }

      if (section.type === "header" && section.content.trim().length === 0) {
        error = "Header content is required.";
        isValid = false;
      }

      if (section.type === "image" && !section.imageFile) {
        error = "Please upload an image file.";
        isValid = false;
      }

      if (error) {
        errorsMap[index] = error;
      }
    });

    const uniqueErrors = Array.from(new Set(Object.values(errorsMap)));

    if (!isValid) {
      notification({
        message: uniqueErrors.join(","),
        type: "warning",
      });
    }

    return isValid;
  };

  const validateMails = (previewMails: string[]) => {
    var isValid = true;

    if (isPreview === "false") return true;

    if (previewMails?.length < 1) {
      notification({
        message: "Please provide at least one email",
        type: "danger",
      });
    } else {
      const validationErrors = previewMails.map((mail) =>
        isEmail(mail) ? "" : "Valid email is required",
      );

      isValid = validationErrors.every((error) => error === "");

      if (!isValid) {
        notification({
          message: "All provided Emails must be valid",
          type: "danger",
        });
      }
    }

    return isValid;
  };

  const onPreview = (e: any) => {
    e.preventDefault();

    if (validation() && validateSections() && validateMails(emails)) {
      const preview = generateEmailTemplate({
        title: data?.messageTitle,
        sections,
      });

      setPreviewHtml(preview);

      setShowPreview(true);
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const onSend = () => {
    if (isPreview === "true") {
      onPreviewSend();
    } else {
      onLiveSend();
    }
  };

  const onPreviewSend = async () => {
    var res = await sendPreviewNotification(
      {
        email: emails,
        messageBody: data?.messageBody,
        messageTitle: data?.messageTitle,
      },
      sections!,
    );

    if (res?.isSuccessful) {
      setErrors({
        MessageBody: [],
        MessageTitle: [],
      });
      setShowPreview(false);
      setPreviewHtml("");
    } else {
      setErrors((state) => ({
        ...state,
        ...res?.data,
      }));
    }
  };

  const onLiveSend = async () => {
    var res = await sendNotification(
      {
        analyticQuery: query,
        messageBody: data?.messageBody,
        messageTitle: data?.messageTitle,
      },
      sections!,
    );

    if (res?.isSuccessful) {
      setData({
        messageBody: "",
        messageTitle: "",
      });
      setSections([]);
      setErrors({
        MessageBody: [],
        MessageTitle: [],
      });
      setShowPreview(false);
      setPreviewHtml("");
    } else {
      setErrors((state) => ({
        ...state,
        ...res?.data,
      }));
    }
  };

  return (
    <>
      <section className="mt-5 flex items-start justify-between gap-5">
        <form onSubmit={onPreview} className="w-[70%]">
          <InputField
            boxStyle="mb-[18px]"
            placeholder="title of campaign"
            label="Campaign Title"
            name="messageTitle"
            value={data?.messageTitle}
            onChange={(e: any) => onFormChange(e)}
            error={errors?.MessageTitle[0]}
            onBlur={() => onErrorChange("MessageTitle", [])}
          />

          <DndProvider backend={HTML5Backend}>
            <h5 className={`mb-2 text-[14px] text-brandgray`}>Message box</h5>

            <div className="flex flex-col gap-5">
              {sections.map((section, index) => (
                <DraggableField
                  key={section?.name}
                  index={index}
                  section={section}
                  moveSection={moveSection}
                  updateSection={updateSection}
                  removeSection={removeSection}
                />
              ))}
            </div>
          </DndProvider>

          <div className="mb-[16px] mt-5 flex items-center justify-between">
            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isPreview === "true"}
                onChange={() => {
                  setIsPreview("true");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Preview Mode
              </label>
            </fieldset>

            <fieldset className="flex items-center gap-2">
              <input
                className="h-[22px] w-[22px] accent-brand"
                type="radio"
                name=""
                id=""
                checked={isPreview === "false"}
                onChange={() => {
                  setIsPreview("false");
                }}
              />
              <label htmlFor="" className="text-[14px]">
                Live Mode
              </label>
            </fieldset>
          </div>

          {isPreview === "true" && (
            <>
              {emails?.length > 0 &&
                emails?.map((doc, index: number) => (
                  <div
                    key={index}
                    className="item-center mb-[18px] flex items-center justify-between gap-3"
                  >
                    <InputField
                      boxStyle="w-2/3"
                      inputStyle="!mb-0"
                      label={`${index + 1}. Email`}
                      placeholder=""
                      name={`emails-${index}`}
                      value={doc}
                      isNumberOnly
                      onChange={(e: any) => onEmailChange(e, index)}
                    />

                    <div className="mt-6 flex w-1/3 items-center gap-3">
                      <button
                        type="button"
                        className={`${btn} !w-1/2 border !border-[#6C18A4] !bg-[#DFA1F4] !text-black`}
                        onClick={() => addNewEmail()}
                      >
                        Add
                      </button>

                      {emails?.length > 1 && (
                        <button
                          type="button"
                          className={`${btn} !w-1/2 border !border-red-500 !bg-red-200 !text-black`}
                          onClick={() => removeEmail(index)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}

          {isPreview === "true" && (
            <button
              className={cx(btn, "!mt-8 w-full bg-yellow-500 text-white")}
            >
              Send Preview
            </button>
          )}

          {isPreview === "false" && (
            <button className={cx(btn, "!mt-8 w-full bg-brand text-white")}>
              Send Message
            </button>
          )}
        </form>
        <div className="w-[30%]">
          <Button className="drag-btn" onClick={() => addSection("image")}>
            <img src={imageIcon} alt="block" />
            <span className="!text-xs">Add Image Block</span>
          </Button>

          <Button className="drag-btn" onClick={() => addSection("text")}>
            <img src={textIcon} alt="block" />
            <span className="!text-xs">Add Text Block</span>
          </Button>

          <Button className="drag-btn" onClick={() => addSection("header")}>
            <img src={textIcon} alt="block" />
            <span className="!text-xs">Add Header Block</span>
          </Button>
        </div>
      </section>

      {showPreview && (
        <Modal
          header={`${
            isPreview ? "Email Preview Mode" : "Email Live Mode"
          } for ${data?.messageTitle} campaign`}
          instruction="Review and confirm the message before proceeding to send"
          bodyStyle="lg:!w-[60%]"
          onClose={() => {
            setShowPreview(false);
            setPreviewHtml("");
          }}
        >
          <div
            className="h-[700px] overflow-y-scroll"
            dangerouslySetInnerHTML={{ __html: previewHtml }}
          />

          <div className="mt-5 flex flex-col-reverse gap-3 md:flex-row">
            <button
              type="button"
              onClick={() => {
                setShowPreview(false);
                setPreviewHtml("");
              }}
              className={cx(btn, "w-full bg-[#F2EAF8] !px-[26px] !py-3")}
            >
              Continue Edit
            </button>
            <button
              onClick={() => onSend()}
              className={cx(btn, "w-full bg-[#DFA1F4] !px-[26px] !py-3")}
            >
              Send {isPreview === "true" ? "Preview" : "Live"} Campaign
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
