import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addAdvert,
  addUpdateDefaultAdvert,
  addUpdateLoyalty,
  changeAdvertVisibility,
  deleteAdvert,
  deleteLoyalty,
  getAdvertById,
  getAdverts,
  getLoyalty,
  reRunAdvert,
  updateAdvert,
  uploadAdvertGraphics,
} from "../api/advert.api";

type StoreState = {
  isLoading: boolean;
  advert: Advert | any;
  advertList: {
    adverts: Advert[];
    defaultAdvert: DefaultAdvert | any;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  loyaltyList: LoyaltyPage;
  getAdverts: (
    query: AdvertQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getAdvertById: (advertId: string) => Promise<void>;
  addAdvert: (advert: NewAdvert) => Promise<UIResponse>;
  updateAdvert: (advertId: string, advert: NewAdvert) => Promise<UIResponse>;
  addUpdateDefaultAd: (
    advert: NewDefaultAdvert,
    advertId: string,
  ) => Promise<UIResponse>;
  reRunAdvert: (
    advertId: string,
    startDate: string,
    endDate: string,
  ) => Promise<UIResponse>;
  uploadAdvertGraphic: (advertId: string, image: string) => Promise<UIResponse>;
  changeAdvertVisibility: (
    advertId: string,
    isVisible: boolean,
  ) => Promise<void>;
  deleteAdvert: (advertId: string) => Promise<void>;
  addUpdateLoyalty: (
    loyalty: NewLoyalty,
    loyaltyId: string,
  ) => Promise<UIResponse>;
  getLoyalty: (pageNumber: number, pageSize: number) => Promise<void>;
  deleteLoyalty: (loyaltyId: string) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  advert: null,
  advertList: {
    adverts: [],
    defaultAdvert: null,
    pageNumber: 0,
    pageSize: 15,
    totalCount: 0,
    totalPage: 0,
  },
  loyaltyList: {
    data: [],
    pageNumber: 0,
    pageSize: 15,
    totalCount: 0,
    totalPage: 0,
  },
};

const useAdvertStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getAdverts: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getAdverts(query, pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              advertList: {
                adverts: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
                defaultAdvert: res?.data?.data?.defaultAd,
              },
            });
          }

          set({ isLoading: false });
        },
        getAdvertById: async (advertId) => {
          set({ isLoading: true });

          var res: UIResponse = await getAdvertById(advertId);

          if (res?.isSuccessful) {
            set({
              advert: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ advert: null, isLoading: false });
          }
        },
        addAdvert: async (advert) => {
          set({ isLoading: true });

          var res: UIResponse = await addAdvert(advert);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: [res?.data?.data, ...state.advertList.adverts],
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        addUpdateDefaultAd: async (advert, advertId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateDefaultAdvert(advert, advertId);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                defaultAdvert: res?.data?.data,
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        deleteAdvert: async (advertId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteAdvert(advertId);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: state?.advertList.adverts?.filter(
                  (item) => item?.id !== advertId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Advert has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        changeAdvertVisibility: async (advertId, isVisible) => {
          set({ isLoading: true });

          var res: UIResponse = await changeAdvertVisibility(advertId);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: state?.advertList.adverts?.map((item) =>
                  item?.id === advertId
                    ? { ...item, visibility: isVisible }
                    : item,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        reRunAdvert: async (advertId, startDate, endDate) => {
          set({ isLoading: true });

          var res: UIResponse = await reRunAdvert(advertId, startDate, endDate);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: state?.advertList.adverts?.map((item) =>
                  item?.id === advertId ? { ...res?.data?.data } : item,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        uploadAdvertGraphic: async (advertId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await uploadAdvertGraphics(advertId, image);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: state?.advertList.adverts?.map((item) =>
                  item?.id === advertId ? { ...res?.data?.data } : item,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });

          return res;
        },
        updateAdvert: async (advertId, advert) => {
          set({ isLoading: true });

          var res: UIResponse = await updateAdvert(advertId, advert);

          if (res?.isSuccessful) {
            set((state) => ({
              advertList: {
                ...state.advertList,
                adverts: state?.advertList?.adverts?.map((item) =>
                  item?.id === advertId ? { ...res?.data?.data } : item,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Advert has been updated successfully`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getLoyalty: async (pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getLoyalty(pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              loyaltyList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        deleteLoyalty: async (loyaltyId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteLoyalty(loyaltyId);

          if (res?.isSuccessful) {
            set((state) => ({
              loyaltyList: {
                ...state.loyaltyList,
                data: state?.loyaltyList.data?.filter(
                  (item) => item?.id !== loyaltyId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Loyalty has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        addUpdateLoyalty: async (loyalty, loyaltyId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateLoyalty(loyalty, loyaltyId);

          if (res?.isSuccessful) {
            if (loyaltyId?.length > 1) {
              set({ isLoading: false });

              set((state) => ({
                loyaltyList: {
                  ...state.loyaltyList,
                  data: state?.loyaltyList?.data?.map((prog) =>
                    prog?.id === loyaltyId
                      ? { ...prog, ...res?.data?.data }
                      : prog,
                  ),
                },
              }));
            } else {
              get().getLoyalty(1, 15);
            }
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message?.length > 1
                ? res?.data?.error?.message
                : res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "advertStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAdvertStore;
