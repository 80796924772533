import InputField from "core/components/formfields/InputField";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { actionbtn, btn } from "core/consts/styling";
import { useEffect, useState } from "react";
import notification from "core/helpers/notification";
import { deleteIcon, editIcon, eyeopen, productImg } from "core/consts/images";
import Modal from "core/components/Modal";
import useTransportationStore from "core/services/stores/useTransportationStore";
import UploadField from "core/components/formfields/UploadField";
import useUserStore from "core/services/stores/useUserStore";

const FleetManagement = () => {
  //tfare states
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const vehicles = useTransportationStore((store) => store.vehicles);
  const getVehiclesAction = useTransportationStore(
    (store) => store.getVehicles,
  );

  // selected
  const [selected, setSelected] = useState<any>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);

  //  actions
  const addUpdateVehicleAction = useTransportationStore(
    (store) => store.addUpdateVehicle,
  );
  const deleteVehicleAction = useTransportationStore(
    (store) => store.deleteVehicle,
  );
  const setLoading = useTransportationStore((store) => store.setLoading);

  const defaultVehicle = {
    colour: "",
    make: "",
    picture: "",
    registrationNumber: "",
    seats: 1,
    vehicleId: "",
    vehicleType: "",
  };

  // form state
  const [newVehicle, setNewVehicle] = useState<NewVehicle>({
    ...defaultVehicle,
  });

  const defaultErrors = {
    Colour: [],
    Make: [],
    Picture: [],
    RegistrationNumber: [],
    Seats: [],
    VehicleId: [],
    VehicleType: [],
  };

  // error state
  const [errors, setErrors] = useState({
    ...defaultErrors,
  });

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;

    setNewVehicle((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // validation
  const validation = (data: NewVehicle, isUpdate: boolean) => {
    var isValid = true;

    if (data?.colour?.length < 1) {
      isValid = false;
      onErrorChange("Colour", ["Color is required"]);
    }

    if (data?.vehicleType?.length < 1) {
      isValid = false;
      onErrorChange("VehicleType", ["Vehicle Type is required"]);
    }

    if (data?.seats < 1) {
      isValid = false;
      onErrorChange("Seats", ["Number of seats must not be less than 1"]);
    }

    if (data?.make?.length < 1) {
      isValid = false;
      onErrorChange("Make", ["Vehicle Make is required"]);
    }

    if (data?.registrationNumber?.length < 1) {
      isValid = false;
      onErrorChange("RegistrationNumber", ["Registration Number is required"]);
    }

    if (data?.vehicleId?.length < 1) {
      isValid = false;
      onErrorChange("VehicleId", ["Vehicle ID is required"]);
    }

    if (data?.picture?.length < 1 && !isUpdate) {
      isValid = false;
      notification({
        type: "warning",
        message: "Please upload a picture of the vehicle",
      });
    }

    return isValid;
  };

  // add update
  const addUpdateVehicle = async (e: any, isUpdate: boolean = false) => {
    e.preventDefault();

    if (validation(newVehicle, isUpdate)) {
      var res = await addUpdateVehicleAction(
        newVehicle,
        isUpdate ? selected?.id : "",
      );

      if (res?.isSuccessful) {
        setNewVehicle({
          ...defaultVehicle,
        });

        if (isUpdate) {
          setSelected(null);
          setShowUpdateModal(false);
        }
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data?.errors,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  // TODO: Add more detailed prompt message

  const deleteVehicle = async (vehicle: Vehicle) => {
    if (window.confirm(`Click 'OK' to delete this vehicle`)) {
      await deleteVehicleAction(vehicle?.id);
    }
  };

  useEffect(() => {
    getVehiclesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto mb-[28px] w-[95%]">
        <header className="mb-[28px] flex items-center gap-3">
          <img src={productImg} loading="lazy" alt="" />
          <h5 className="text-[18px] font-[500] text-brand">
            Fleet Management System
          </h5>
        </header>
        <div className="mb-[28px] flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Add New Vehicle</p>
              </div>
              <form onSubmit={(e: any) => addUpdateVehicle(e, false)}>
                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Vehicle Type"
                  name="vehicleType"
                  value={newVehicle?.vehicleType}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.VehicleType[0]}
                  onBlur={() => onErrorChange("VehicleType", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Number of Seats"
                  label="Number of Seats"
                  name="seats"
                  type="number"
                  value={newVehicle?.seats}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Seats[0]}
                  onBlur={() => onErrorChange("Seats", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Registration Number"
                  name="registrationNumber"
                  value={newVehicle?.registrationNumber}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.RegistrationNumber[0]}
                  onBlur={() => onErrorChange("RegistrationNumber", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Make"
                  name="make"
                  value={newVehicle?.make}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Make[0]}
                  onBlur={() => onErrorChange("Make", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Colour"
                  name="colour"
                  value={newVehicle?.colour}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.Colour[0]}
                  onBlur={() => onErrorChange("Colour", [])}
                />

                <InputField
                  boxStyle="mb-[24px]"
                  placeholder="Vehicle ID"
                  name="vehicleId"
                  value={newVehicle?.vehicleId}
                  onChange={(e: any) => onFormChange(e)}
                  error={errors?.VehicleId[0]}
                  onBlur={() => onErrorChange("VehicleId", [])}
                />

                <UploadField
                  boxStyle="mb-[24px]"
                  label="Upload Vehicle Picture"
                  name="picture"
                  onChange={setNewVehicle}
                  value={newVehicle?.picture}
                />

                <button
                  disabled={isSuperAdmin}
                  className={`${btn} w-full bg-brand text-white`}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Vehicle Type",
                "Make",
                "Seats",
                "Registration Number",
                "Colour",
                "Vehicle #ID",
                "Date Added",
                "Action",
              ]}
            >
              {vehicles?.length > 0 ? (
                vehicles?.map((vehicle) => (
                  <tr key={vehicle?.id}>
                    <Row value={vehicle?.vehicleType} />
                    <Row value={vehicle?.make} />
                    <Row value={vehicle?.seats} />
                    <Row value={vehicle?.registrationNumber} />
                    <Row value={vehicle?.colour} />
                    <Row value={vehicle?.vehicleId} />
                    <Row value={vehicle?.dateAdded} />
                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] !border-[#6C18A4] !bg-[#DFA1F4] p-2"
                        onClick={() => {
                          setSelected(vehicle);

                          setLoading(true);
                          setShowDocModal(true);
                        }}
                      >
                        <img
                          src={eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        onClick={() => {
                          setErrors({ ...defaultErrors });

                          setSelected(vehicle);

                          setShowUpdateModal(true);

                          setNewVehicle({
                            colour: vehicle?.colour,
                            make: vehicle?.make,
                            picture: "",
                            registrationNumber: vehicle?.registrationNumber,
                            seats: vehicle?.seats,
                            vehicleId: vehicle?.vehicleId,
                            vehicleType: vehicle?.vehicleType,
                          });
                        }}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
                        onClick={() => deleteVehicle(vehicle)}
                      >
                        <img
                          src={deleteIcon}
                          className="h-[16px]"
                          loading="lazy"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={8} value="no vehicle has been added." />
                </tr>
              )}
            </Table>
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <Modal
          header="Update Vehicle"
          onClose={() => {
            setShowUpdateModal(false);

            setNewVehicle({
              ...defaultVehicle,
            });

            setErrors({ ...defaultErrors });
          }}
        >
          <form onSubmit={(e: any) => addUpdateVehicle(e, true)}>
            <InputField
              boxStyle="mb-[24px]"
              placeholder="Vehicle Type"
              name="vehicleType"
              value={newVehicle?.vehicleType}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.VehicleType[0]}
              onBlur={() => onErrorChange("VehicleType", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Number of Seats"
              label="Number of Seats"
              name="seats"
              type="number"
              value={newVehicle?.seats}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Seats[0]}
              onBlur={() => onErrorChange("Seats", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Registration Number"
              name="registrationNumber"
              value={newVehicle?.registrationNumber}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.RegistrationNumber[0]}
              onBlur={() => onErrorChange("RegistrationNumber", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Make"
              name="make"
              value={newVehicle?.make}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Make[0]}
              onBlur={() => onErrorChange("Make", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Colour"
              name="colour"
              value={newVehicle?.colour}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.Colour[0]}
              onBlur={() => onErrorChange("Colour", [])}
            />

            <InputField
              boxStyle="mb-[24px]"
              placeholder="Vehicle ID"
              name="vehicleId"
              value={newVehicle?.vehicleId}
              onChange={(e: any) => onFormChange(e)}
              error={errors?.VehicleId[0]}
              onBlur={() => onErrorChange("VehicleId", [])}
            />

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowUpdateModal(false);

                  setNewVehicle({
                    ...defaultVehicle,
                  });

                  setErrors({ ...defaultErrors });
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
              >
                Close
              </button>
              <button
                className={`${actionbtn} w-full bg-[#DFA1F4] !px-[26px] !py-3`}
              >
                Update
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showDocModal && (
        <Modal
          header={`Vehicle - ${selected?.registrationNumber}`}
          onClose={() => {
            setSelected(null);
            setShowDocModal(false);
          }}
        >
          <div className="flex flex-row items-center justify-center">
            <img
              src={selected?.imageUrl}
              alt={selected?.registrationNumber}
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              loading="lazy"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default FleetManagement;
