import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import TextField from "./formfields/TextField";
import InputField from "./formfields/InputField";
import { deleteIcon } from "core/consts/images";

interface Props {
  section: DraggableSection;
  index: number;
  moveSection: (dragIndex: number, hoverIndex: number) => void;
  updateSection: (name: string, content: string, imageFile?: File) => void;
  removeSection: (name: string) => void;
}

export const DraggableField: React.FC<Props> = ({
  section,
  index,
  moveSection,
  updateSection,
  removeSection,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "section",
    hover: (item: { index: number }) => {
      if (!ref.current || item.index === index) return;
      moveSection(item.index, index);
      item.index = index;
    },
  });

  const [, drag] = useDrag({
    type: "section",
    item: { index },
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="flex items-start justify-between gap-2">
      {/* Text Section */}
      {section.type === "text" && (
        <TextField
          placeholder="Add texts"
          boxStyle="w-full"
          textareaStyle="hover:!cursor-grab"
          name={section?.name}
          value={section?.content}
          onChange={(e: any) => updateSection(section?.name, e.target.value)}
        />
      )}

      {/* Header Section */}
      {section.type === "header" && (
        <InputField
          placeholder="Add header text"
          boxStyle="w-full"
          inputStyle="hover:!cursor-grab"
          value={section.content}
          name={section?.name}
          onChange={(e: any) => updateSection(section?.name, e.target.value)}
        />
      )}

      {/* Raw Image Section */}
      {section.type === "image" && (
        <InputField
          type="file"
          boxStyle="w-full"
          inputStyle="hover:!cursor-grab"
          onChange={(e: any) => {
            const file = e.target.files?.[0];
            if (file) {
              updateSection(section?.name, URL.createObjectURL(file), file);
            }
          }}
        />
      )}

      {/* Delete Button */}
      <button
        className="flex h-[28px] w-[28px] items-center justify-center rounded-md border-[1px] border-[#FF0000] bg-[#FFC8C8] p-2"
        onClick={() => {
          removeSection(section?.name);
        }}
      >
        <img
          src={deleteIcon}
          className="h-[14px] w-[16px]"
          loading="lazy"
          alt="delete"
        />
      </button>
    </div>
  );
};
