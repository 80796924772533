import React from "react";
import PropTypes from "prop-types";

function Button({
  children,
  className,
  onClick,
  disabled,
  fontSize,
  type,
  bgColor,
  textColor,
  paddingY,
  paddingX,
  border,
}: any) {
  return (
    <button
      className={`${fontSize} ${bgColor} ${textColor} ${paddingX} ${paddingY} rounded ${
        border ? "border-purple-p border" : ""
      } flex items-center justify-center py-4 transition ease-in-out hover:bg-opacity-70 disabled:border-0 ${className} disabled:bg-primary disabled:cursor-default disabled:bg-opacity-30 disabled:text-white`}
      type={type === "submit" ? "submit" : "button"}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  paddingY: PropTypes.string,
  paddingX: PropTypes.string,
  border: PropTypes.bool,
};

Button.defaultProps = {
  children: <div></div>,
  className: "",
  type: "button",
  onClick: () => {},
  disabled: false,
  fontSize: "text-sm",
  bgColor: "bg-purple-p",
  textColor: "text-white",
  paddingY: "py-2",
  paddingX: "px-6",
  border: false,
};

export default Button;
