import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import {
  activeModule,
  downloadImg,
  editIcon,
  eyeopen,
} from "core/consts/images";
import { btn } from "core/consts/styling";
import { exportToCSV } from "core/helpers/exportToExcel";
import notification from "core/helpers/notification";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AssignDriverToBus = () => {
  // need state
  const vehicles = useTransportationStore((store) => store.vehicles);
  const drivers = useTransportationStore((store) => store.drivers);

  // actions
  const getVehiclesAction = useTransportationStore(
    (store) => store.getVehicles,
  );

  const getDriversAction = useTransportationStore((store) => store.getDrivers);

  const assignDriverToVehicleAction = useTransportationStore(
    (store) => store.assignDriverToVehicle,
  );

  const setLoading = useTransportationStore((store) => store.setLoading);

  const [selected, setSelected] = useState<any>(null);
  // const [showViewModal, setShowViewModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);

  const [assignVehicle, setAssignVehicle] = useState({
    driverId: "",
    vehicleId: "",
  });

  const [query, setQuery] = useState<TripQuery>({
    search: "",
    status: "",
    startDate: "",
    endDate: "",
    locationFilter: "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const onFormChange = (e: any) => {
    const { name, value } = e.target;
    setAssignVehicle((state) => ({ ...state, [name]: value }));
  };

  const search = (e: any) => {
    e.preventDefault();
  };

  const assignDriverToVehicle = async (e: any) => {
    e?.preventDefault();

    if (
      assignVehicle?.driverId?.length > 0 &&
      assignVehicle?.vehicleId?.length > 0
    ) {
      var res = await assignDriverToVehicleAction(
        assignVehicle?.vehicleId,
        assignVehicle?.driverId,
      );

      if (res?.isSuccessful) {
        setAssignVehicle({
          driverId: "",
          vehicleId: "",
        });
      }
    } else {
      notification({
        message: "Please choose a vehicle and a driver",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    drivers?.length < 1 && getDriversAction();
    vehicles?.length < 1 && getVehiclesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex flex-col  justify-start gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Assign Driver to Bus
            </h5>
          </div>
          <div className="flex items-center gap-5 justify-between">
            <Link
              className="text-brand hover:underline"
              to="/transportation/plan-trips"
            >
              Plan Trips
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/manage-trips"
            >
              Manage Trips
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/transportation/driver-schedule"
            >
              Driver Schedule
            </Link>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              disabled
              value={query?.search}
              onChange={onQueryChange}
            />

            <InputField
              label="Start Date"
              type="date"
              disabled
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              disabled
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button
                disabled
                className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3 px-8 text-white disabled:cursor-not-allowed"
              >
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled={vehicles == null || vehicles?.length < 1}
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  exportToCSV(vehicles, "Vehicles");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>

        <section className="mb-[28px] flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-4/12">
            <div className="w-full rounded-[5px] bg-white p-5">
              <div className="mb-[28px] text-center">
                <p>Assign Vehicle To Driver</p>
              </div>
              <form onSubmit={(e: any) => assignDriverToVehicle(e)}>
                <SelectField
                  boxStyle="mb-[18px]"
                  label="Select Vehicle"
                  name="vehicleId"
                  defaultName="Select Vehicle"
                  defaultValue=""
                  options={
                    vehicles?.length > 0
                      ? [
                          ...vehicles?.map((vehicle) => ({
                            name: `${vehicle?.vehicleType}- ${vehicle?.registrationNumber}`,
                            value: vehicle?.id,
                          })),
                        ]
                      : []
                  }
                  value={assignVehicle?.vehicleId}
                  onChange={(e: any) => onFormChange(e)}
                />

                <SelectField
                  boxStyle="mb-[18px]"
                  label="Select Driver"
                  name="driverId"
                  defaultName="Select Driver"
                  defaultValue=""
                  options={
                    drivers?.length > 0
                      ? [
                          ...drivers?.map((driver) => ({
                            name: driver?.fullName,
                            value: driver?.agentId,
                          })),
                        ]
                      : []
                  }
                  value={assignVehicle?.driverId}
                  onChange={(e: any) => onFormChange(e)}
                />

                <button className={`${btn} w-full bg-brand text-white`}>
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className="w-full lg:w-8/12">
            <Table
              headers={[
                "Bus #ID",
                "Registration #",
                "Number of Seats",
                "Info",
                "Date Created",
                "Action",
              ]}
            >
              {vehicles?.length > 0 ? (
                vehicles?.map((vehicle) => (
                  <tr key={vehicle?.id}>
                    <Row value={vehicle?.vehicleId} />
                    <Row value={vehicle?.registrationNumber} />
                    <Row value={vehicle?.seats} />
                    <Row
                      value={`${vehicle?.vehicleType} ${vehicle?.make} ${vehicle?.colour}`}
                    />
                    <Row value={vehicle?.dateAdded} />
                    <ActionRow style={`flex items-center justify-center gap-3`}>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] !border-[#6C18A4] !bg-[#DFA1F4] p-2"
                        onClick={() => {
                          setSelected(vehicle);

                          setLoading(true);
                          setShowDocModal(true);
                        }}
                      >
                        <img
                          src={eyeopen}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                      <button
                        className="flex w-[35px] items-center justify-center border-[1px] border-[#59960B] bg-[#D9FEAA] p-2"
                        disabled
                        onClick={() => {}}
                      >
                        <img
                          src={editIcon}
                          loading="lazy"
                          className="h-[16px]"
                          alt=""
                        />
                      </button>
                    </ActionRow>
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={6} value="No vehicles yet" />
                </tr>
              )}
            </Table>
          </div>
        </section>
      </div>

      {showDocModal && (
        <Modal
          header={`Vehicle - ${selected?.registrationNumber}`}
          onClose={() => {
            setSelected(null);
            setShowDocModal(false);
          }}
        >
          <div className="flex flex-row items-center justify-center">
            <img
              src={selected?.imageUrl}
              alt={selected?.registrationNumber}
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              loading="lazy"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AssignDriverToBus;
