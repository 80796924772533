import Modal from "core/components/Modal";
import InputField from "core/components/formfields/InputField";
import SelectField from "core/components/formfields/SelectField";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import Table from "core/components/table/Table";
import { activeModule, downloadImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import { RIDE_STATUS_LIST } from "core/consts/systemConst";
import { exportToCSV } from "core/helpers/exportToExcel";
import {
  formatCurrency,
  getDate,
  getDateTime,
  getMonthStartEnd,
} from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useLocationStore from "core/services/stores/useLocationStore";
import useTransportationStore from "core/services/stores/useTransportationStore";
import useUserStore from "core/services/stores/useUserStore";
import { useEffect, useState } from "react";
import { male } from "core/consts/images";
import Pagination from "core/components/Pagination";

const TripInfo = ({
  name,
  value,
}: {
  name: string;
  value?: string | number;
}) => {
  return (
    <div className="mb-[28px] text-center text-[14px] font-bold">
      <span className="text-[12px] font-[400] text-[#868686]">{name}</span>
      <br />
      {value}
    </div>
  );
};

const ManageTransportOrders = () => {
  // need state
  //const navigate = useNavigate();
  const user = useUserStore((store) => store.user);
  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const transportOrderList = useTransportationStore(
    (store) => store.transportOrderList,
  );
  const getTransportOrderAction = useTransportationStore(
    (store) => store.getTransportOrder,
  );
  const [showViewModal, setShowViewModal] = useState(false);
  const transportOrderDetail = useTransportationStore(
    (store) => store.transportOrderDetail,
  );
  const getTransportOrderByIdAction = useTransportationStore(
    (store) => store.getTransportOrderById,
  );

  const startEndDate = getMonthStartEnd();
  const [query, setQuery] = useState<TransportOrderQuery>({
    search: "",
    startDate: startEndDate.start,
    endDate: startEndDate.end,
    bookingStatus: "",
    locationFilter: !isSuperAdmin ? user?.location : "",
  });

  const onQueryChange = (e: any) => {
    const { name, value } = e.target;
    setQuery((state) => ({ ...state, [name]: value }));
  };

  const viewOrderDetail = async (tripId: string) => {
    await getTransportOrderByIdAction(tripId);
    setShowViewModal(true);
  };

  const search = (e: any) => {
    e.preventDefault();

    /*
    if (!query?.startDate || !query?.endDate) {
      notification({
        message: "The start date and end date is required",
        type: "warning",
      });
      return;
    }*/
    getTransportOrderAction(
      query,
      transportOrderList?.pageNumber,
      transportOrderList?.pageSize,
    );
  };

  const fetchMore = async (page: number) => {
    await getTransportOrderAction(query, page, transportOrderList?.pageSize);
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getTransportOrderAction(
      query,
      transportOrderList?.pageNumber,
      transportOrderList?.pageSize,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add filter by status

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex items-center justify-between">
          <div className="flex items-center gap-3">
            <img src={activeModule} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">
              Manage Transport Orders
            </h5>
          </div>
        </header>

        <section>
          <form
            className="mb-[28px] flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
            onSubmit={search}
          >
            <SelectField
              label="Select Location"
              boxStyle="min-w-[200px]"
              selectStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              defaultName="Select your Location"
              defaultValue=""
              disabled={!isSuperAdmin}
              name="locationFilter"
              options={
                locations?.length > 0
                  ? [
                      ...locations?.map((location) => ({
                        name: location?.name,
                        value: location?.name,
                      })),
                    ]
                  : []
              }
              value={query?.locationFilter}
              onChange={onQueryChange}
            />

            <InputField
              label="Search"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="search"
              value={query?.search}
              onChange={onQueryChange}
            />

            <SelectField
              selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
              name="bookingStatus"
              boxStyle="min-w-[200px]"
              defaultName="Filter by Booking Status"
              defaultValue=""
              options={RIDE_STATUS_LIST}
              value={query?.bookingStatus}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />

            <InputField
              label="Start Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="startDate"
              value={query?.startDate}
              onChange={onQueryChange}
            />

            <InputField
              label="End  Date"
              type="date"
              boxStyle="min-w-[200px]"
              inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
              name="endDate"
              value={query?.endDate}
              onChange={onQueryChange}
            />

            <div className="flex min-w-[400px] items-center gap-3">
              <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3  px-8 text-white">
                <span className="text-[14px text-brand">Search</span>
              </button>

              <button
                type="button"
                disabled={
                  transportOrderList == null ||
                  transportOrderList?.orders?.length < 1
                }
                className="flex h-[50px] items-center justify-between gap-3 rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
                onClick={() => {
                  exportToCSV(transportOrderList?.orders, "Transport Orders");

                  notification({
                    message: "You have successfully exported the data to excel",
                    type: "success",
                  });
                }}
              >
                <span className="text-[14px]">Download</span>
                <img src={downloadImg} alt="" loading="lazy" />
              </button>
            </div>
          </form>
        </section>
        <section className="mb-[28px]">
          <div className="mb-[24px] grid grid-cols-2 gap-3 rounded-[25px] lg:grid-cols-4">
            <button className={`${actionbtn} bg-[#F2EAF8] shadow-md`}>
              Total Count: {transportOrderList?.totalCount}
            </button>

            <button className={`${actionbtn} !bg-brand !text-white shadow-md`}>
              Total Fare: {formatCurrency(transportOrderList?.totalFare)}
            </button>
          </div>

          <Table
            headers={[
              "Route",
              "Customer Name",
              "Departure Date",
              "Departure Time",
              "Total Fare",
              "Seat Number",
              "Ticket Number",
              "Booking Status",
              "Booking Date",
              "Action",
            ]}
          >
            {transportOrderList != null &&
            transportOrderList?.orders?.length > 0 ? (
              transportOrderList?.orders?.map((order) => (
                <tr key={order?.id}>
                  <Row value={order?.route} />
                  <Row value={order?.customerName} />
                  <Row value={getDate(order?.departureDate)} />
                  <Row value={order?.departureTime} />
                  <Row value={formatCurrency(order?.totalFare)} />
                  <Row value={order?.seatNumber} />
                  <Row value={order?.ticketNumber} />
                  <Row value={order?.bookingStatus} />
                  <Row value={getDateTime(order?.bookingDate)} />
                  <ActionRow style={`flex items-center justify-center gap-3`}>
                    <button
                      className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                      onClick={() => {
                        viewOrderDetail(order?.id);
                      }}
                    >
                      View
                    </button>
                  </ActionRow>
                </tr>
              ))
            ) : (
              <tr>
                <Row colSpan={10} value="No transport order yet" />
              </tr>
            )}
          </Table>

          <Pagination
            pageNumber={transportOrderList?.pageNumber}
            pageSize={transportOrderList?.pageSize}
            totalCount={transportOrderList?.totalCount}
            totalPage={transportOrderList?.totalPage}
            onFetch={fetchMore}
          />
        </section>
      </div>

      {showViewModal && (
        <Modal
          bodyStyle="!w-[60%]"
          header="Transport Order Detail"
          onClose={() => {
            setShowViewModal(false);
          }}
        >
          <div>
            {transportOrderDetail != null ? (
              <div className="grid grid-cols-1 items-center justify-center gap-2 md:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-full mb-[28px] flex items-center justify-center">
                  <img src={male} alt="" loading="lazy" className="" />
                </div>

                <TripInfo
                  name="Customer Name"
                  value={transportOrderDetail?.customerName}
                />

                <TripInfo
                  name="Email"
                  value={transportOrderDetail?.customerEmail}
                />

                <TripInfo
                  name="Customer Phone number"
                  value={transportOrderDetail?.customerPhoneNumber}
                />

                <TripInfo
                  name="Seat Number"
                  value={transportOrderDetail?.seatNumber}
                />

                <TripInfo
                  name="Bus Route"
                  value={transportOrderDetail?.route}
                />

                <TripInfo
                  name="Bus Stop"
                  value={transportOrderDetail?.busStop}
                />

                <TripInfo
                  name="Departure Time"
                  value={transportOrderDetail?.departureTime}
                />

                <TripInfo
                  name="Departure Date"
                  value={getDate(transportOrderDetail?.departureDate)}
                />

                <TripInfo
                  name="Total fare"
                  value={formatCurrency(transportOrderDetail?.totalFare)}
                />

                <TripInfo
                  name="Convenience Fee"
                  value={formatCurrency(transportOrderDetail?.convenienceFee)}
                />

                <TripInfo name="Trip Id" value={transportOrderDetail?.tripId} />

                <TripInfo
                  name="Bus Name"
                  value={transportOrderDetail?.busName}
                />

                <TripInfo
                  name="Driver Name"
                  value={transportOrderDetail?.riderName}
                />

                <TripInfo
                  name="Ticket number"
                  value={transportOrderDetail?.ticketNumber}
                />

                <TripInfo
                  name="Booking Status"
                  value={transportOrderDetail?.bookingStatus}
                />

                <TripInfo
                  name="Passenger Status"
                  value={transportOrderDetail?.passengerStatus}
                />

                <TripInfo
                  name="Booking Date"
                  value={getDateTime(transportOrderDetail?.bookingDate!) || ""}
                />

                <TripInfo
                  name="Payment Reference"
                  value={transportOrderDetail?.paymentRef}
                />
              </div>
            ) : (
              <div className="mb-5">
                <p>
                  The details for this order could not be retrieved at the
                  moment
                </p>
              </div>
            )}
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => {
                  setShowViewModal(false);
                }}
                className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 md:w-1/2 lg:w-1/3 `}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageTransportOrders;
