import SelectBox from "core/components/formfields/SelectField";
import { downloadImg, users } from "core/consts/images";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";
import SelectField from "core/components/formfields/SelectField";
import DatePicker from "core/components/formfields/DatePicker";
import { GENDERS, REGISTRATION_STATUS } from "core/consts/systemConst";
import { exportToCSV } from "core/helpers/exportToExcel";
import notification from "core/helpers/notification";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import InputField from "core/components/formfields/InputField";
import Table from "core/components/table/Table";
import ActionRow from "core/components/table/ActionRow";
import Row from "core/components/table/Row";
import { getMonthStartEnd } from "core/helpers/generalHelpers";
import { actionbtn } from "core/consts/styling";

const DataCenter = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const customerData = useAnalyticStore((store) => store.customerData);
  const getCustomerDataListAction = useAnalyticStore(
    (store) => store.getCustomerDataList,
  );
  const [isChecked, setIsChecked] = useState(true);
  const [selectedData, setSelectedData] = useState<CustomerData[]>([]);
  const startEndDate = getMonthStartEnd();

  const [query, setQuery] = useState<UserDataQueryParam>({
    startDate: startEndDate?.start,
    endDate: startEndDate?.end,
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    gender: "",
    status: "",
  });

  const exportData = async () => {
    if (selectedData?.length > 0) {
      exportToCSV(selectedData, "Customer Data");

      setSelectedData([]);
      setIsChecked(false);

      notification({
        message: "You have successfully exported the data to excel",
        type: "success",
      });
    } else {
      notification({
        message: "Please select the field you want to export",
        type: "danger",
      });
    }
  };

  const onQueryChange = (
    name: string,
    value: string,
    isSearch: boolean = false,
  ) => {
    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (!isSearch) {
      getCustomerDataListAction({
        ...query,
        [name]: value,
      });
    }
  };

  const search = (e: any) => {
    e.preventDefault();

    getCustomerDataListAction({ ...query });
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();

    customerData?.length < 1 && getCustomerDataListAction(query);

    setQuery((state: any) => ({
      ...state,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-[14px] flex items-end gap-3">
        <img src={users} alt="" loading="lazy" />
        <h5 className="text-[18px] font-[500] text-brand">
          Customer Data Download Centre
        </h5>
      </header>
      <div className="mb-[24px] flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full lg:w-8/12">
          <section>
            <form
              className="flex items-end justify-between gap-2 overflow-x-scroll rounded-[5px] border border-t-[3px] border-brand bg-white p-3"
              onSubmit={search}
            >
              <InputField
                label="First Name"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="firstName"
                value={query?.firstName}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Last Name"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="firstName"
                value={query?.lastName}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Email"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="email"
                value={query?.email}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <InputField
                label="Phone Number"
                boxStyle="min-w-[120px]"
                inputStyle="border-white rounded-none !border-b !border-b-[1px] !border-b-brandgray"
                name="phoneNumber"
                value={query?.phoneNumber}
                onChange={(e: any) =>
                  onQueryChange(e?.target?.name, e?.target?.value, true)
                }
              />

              <div className="flex items-center gap-3">
                <button className="flex h-[50px] items-center justify-center gap-3 rounded-[5px] border border-brand bg-[#DFA1F4] p-3 px-8 text-white">
                  <span className="text-[14px text-brand">Search</span>
                </button>
              </div>
            </form>
          </section>

          <section className="my-[24px] flex justify-start gap-3 rounded-[25px]">
            <div className="flex justify-start gap-3 rounded-[25px]">
              <button
                className={`${actionbtn} !bg-brand !text-white shadow-md`}
              >
                Total Customer: {customerData?.length}
              </button>
            </div>
          </section>

          <section>
            <Table
              headers={[
                "checkbox",
                "First Name",
                "Last Name",
                "Email Address",
                "Phone Number",
                "Location",
              ]}
              isChecked={isChecked}
              onCheck={(e: any) => {
                setIsChecked(e?.target?.checked);

                if (e?.target?.checked) {
                  setSelectedData([...customerData]);
                } else {
                  setSelectedData([]);
                }
              }}
            >
              {customerData?.length > 0 ? (
                customerData?.map((data) => (
                  <tr key={data?.id}>
                    <ActionRow style={`flex items-center justify-center`}>
                      <input
                        type="checkbox"
                        className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
                        checked={selectedData?.some(
                          (cus) => cus?.id === data?.id,
                        )}
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            setSelectedData((state) => [...state, { ...data }]);
                          } else {
                            setSelectedData((state) =>
                              state.filter((el) => el.id !== data?.id),
                            );
                          }
                        }}
                      />
                    </ActionRow>
                    <Row value={data?.firstName} />
                    <Row value={data?.lastName} />
                    <Row value={data?.email} />
                    <Row value={data?.phoneNumber} />
                    <Row value={data?.location} />
                  </tr>
                ))
              ) : (
                <tr>
                  <ActionRow style={`flex items-center justify-center`}>
                    <input
                      disabled
                      type="checkbox"
                      className="border-1 h-[25px] w-[25px] rounded-[5px] border border-red-500 text-white !accent-[#DFA1F4]"
                      name=""
                      id=""
                    />
                  </ActionRow>
                  <Row colSpan={6} value="no customer data yet" />
                </tr>
              )}
            </Table>
          </section>
        </div>
        <div className="w-full lg:w-4/12">
          <SelectField
            boxStyle="mb-[18px]"
            selectStyle="!border-brand text-[#615353]"
            name="location"
            defaultName="Select your Location"
            defaultValue=""
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      name: location?.name,
                      value: location?.name,
                    })),
                  ]
                : []
            }
            value={query?.location}
            onChange={(e: any) =>
              onQueryChange(e?.target?.name, e?.target?.value)
            }
          />

          <SelectBox
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="gender"
            defaultName="Filter By Gender"
            defaultValue=""
            options={GENDERS?.length > 0 ? [...GENDERS] : []}
            value={query?.gender}
            onChange={(e: any) =>
              onQueryChange(e?.target?.name, e?.target?.value)
            }
          />

          <SelectBox
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="status"
            defaultName="Filter By Status"
            defaultValue=""
            options={
              REGISTRATION_STATUS?.length > 0 ? [...REGISTRATION_STATUS] : []
            }
            value={query?.status}
            onChange={(e: any) =>
              onQueryChange(e?.target?.name, e?.target?.value)
            }
          />

          <div className="mb-[18px] grid grid-cols-2 gap-5 lg:grid-cols-2">
            <DatePicker
              name="startDate"
              label="Start Date"
              value={query?.startDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) =>
                onQueryChange(e?.target?.name, e?.target?.value)
              }
            />
            <DatePicker
              name="endDate"
              label="End Date"
              value={query?.endDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => {
                onQueryChange(e?.target?.name, e?.target?.value);
              }}
            />
          </div>

          <button
            disabled={selectedData?.length < 1}
            className="flex w-full items-center justify-between rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
            onClick={() => exportData()}
          >
            <span className="text-[14px]">Download</span>
            <img src={downloadImg} loading="lazy" alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default DataCenter;
