/* eslint-disable eqeqeq */
import loansImg from "assets/icons/loans.svg";
import SelectField from "core/components/formfields/SelectField";
import TextField from "core/components/formfields/TextField";
import LoanInfo from "core/components/LoanInfo";
import { actionbtn } from "core/consts/styling";
import {
  cx,
  formatCurrency,
  getDate,
  getLoanStatusColor,
} from "core/helpers/generalHelpers";
import useLoanStore from "core/services/stores/useLoanStore";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import chevRight from "assets/icons/chevRight.svg";
import chevLeft from "assets/icons/chevLeft.svg";
import Table from "core/components/table/Table";
import notification from "core/helpers/notification";
import { LOAN_STATUS_LIST, REPAYMENT_STATUS } from "core/consts/systemConst";
import Pagination from "core/components/Pagination";
import Row from "core/components/table/Row";
import caretUp from "assets/icons/greenCaretUp.svg";
import caretDown from "assets/icons/greenCaretDown.svg";
import Modal from "core/components/Modal";

const LoanDetails = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [decision, setDecision] = useState("");
  const [comments, setComments] = useState("");

  const [showRepayments, setShowRepayments] = useState(false);
  const userRequestList = useLoanStore((store) => store.loanRequestList);
  const getUserRequestListAction = useLoanStore(
    (store) => store.getLoanRequests,
  );
  const request = useLoanStore((store) => store.singleLoanRequest);
  const getLoanRequestAction = useLoanStore(
    (store) => store.getLoanRequestById,
  );
  const approveDeclineLoanAction = useLoanStore(
    (store) => store.approveDeclineLoan,
  );
  const [selectedDoc, setSelectedDoc] = useState<LoanDocument | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (request?.loanDocuments?.length! > 0) {
      setSelectedDoc(request?.loanDocuments[0]!);
    }
  }, [request]);

  const handleNext = () => {
    if (request && currentIndex < request.loanDocuments.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setSelectedDoc(request.loanDocuments[newIndex]);
    }
  };

  const handlePrevious = () => {
    if (request && currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setSelectedDoc(request.loanDocuments[newIndex]);
    }
  };

  const query: LoanQuery = {
    endDate: "",
    locationId: "",
    showOnlyDefaultedLoans: false,
    startDate: "",
    status: "",
    userId: request?.userId!,
  };

  const fetchMore = async (page: number) => {
    await getUserRequestListAction(
      {
        ...query,
      },
      page,
      userRequestList?.pageSize,
    );
  };

  const validateDecision = (decision: string, comment: string) => {
    var isValid = true;

    if (decision?.length < 1) {
      isValid = false;
      notification({
        message: "Please choose a decision",
        type: "warning",
      });
    } else {
      if (decision === "Declined" && comment?.length < 1) {
        notification({
          message: "Please include additional comments",
          type: "warning",
        });
        isValid = false;
      }
    }

    return isValid;
  };

  const approveDeclineLoan = async (e: any) => {
    e.preventDefault();

    if (validateDecision(decision, comments)) {
      await approveDeclineLoanAction(
        requestId!,
        comments,
        decision === "Approved",
      );

      setComments("");
      setDecision("");
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  const infoTab =
    "col-span-full lg:col-span-6 xl:col-span-3 row-span-1 min-h-[340px] rounded-[8px] border bg-white p-4";

  useEffect(() => {
    if (requestId != null && requestId?.length > 1) {
      getLoanRequestAction(requestId);
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserRequestListAction({ ...query }, 1, 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request?.userId]);

  return (
    <>
      <div className="mx-auto w-[95%]">
        <header className="mb-[28px] flex flex-col justify-start lg:flex-row lg:justify-between">
          <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
            <img src={loansImg} loading="lazy" alt="" />
            <h5 className="text-[18px] font-[500] text-brand">Loan Request</h5>
          </div>
          <div className="flex items-center gap-5">
            <Link
              className="text-brand hover:underline"
              to="/superadmin/loan-management"
            >
              Loan Service Management
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/superadmin/loan-management/loan-parameter"
            >
              Loan Parameters
            </Link>
            <Link
              className="text-brand hover:underline"
              to="/superadmin/loan-management/settings"
            >
              Settings
            </Link>
          </div>
        </header>

        <section className="mb-[28px] grid w-full grid-cols-12 gap-3 xl:grid-rows-3 xl:gap-5">
          <section className={infoTab}>
            <p className="mb-5 font-[500] text-[#31054E]">
              Personal Information
            </p>

            <LoanInfo
              name="First name"
              value={request?.user?.firstName ?? "N/A"}
            />

            <LoanInfo
              name="Last name"
              value={request?.user?.lastName ?? "N/A"}
            />

            <LoanInfo
              name="School"
              value={request?.user?.location?.name ?? "N/A"}
            />

            <LoanInfo
              name="Phone Number"
              value={request?.user?.phoneNumber ?? "N/A"}
            />

            <LoanInfo name="Email" value={request?.user?.email ?? "N/A"} />

            <LoanInfo name="Levels" value={"N/A"} />
          </section>
          <section className={cx(infoTab)}>
            <p className="mb-5 font-[500] text-[#31054E]">Loans Requests</p>

            <LoanInfo
              name="Pre-Qualified Amount"
              nameStyle="!w-[160px]"
              value={formatCurrency(request?.eligibleAmount) ?? "N/A"}
            />

            <LoanInfo
              name="Amount Requested"
              nameStyle="!w-[160px]"
              value={formatCurrency(request?.loanAmount) ?? "N/A"}
            />

            <LoanInfo
              name="Tenor in months"
              nameStyle="!w-[160px]"
              value={request?.tenorInMonths ?? "N/A"}
            />

            <LoanInfo
              name="Loan Eligibility"
              nameStyle="!w-[160px]"
              value={formatCurrency(request?.eligibleAmount) ?? "N/A"}
            />
            <LoanInfo
              name="Returning Customer"
              nameStyle="!w-[160px]"
              value={userRequestList?.totalCount > 0 ? "Yes" : "No"}
            />
          </section>

          <section
            className={cx(
              infoTab,
              "!col-span-full !row-span-2 !h-auto xl:!col-span-6",
            )}
          >
            <div className="mb-5 flex items-center justify-between gap-3">
              <p className="font-[500] text-[#31054E]">Documentations</p>

              <div className="flex items-center justify-between gap-2">
                <img
                  src={chevLeft}
                  className="cursor-pointer"
                  alt="Left"
                  onClick={handlePrevious}
                />
                <span>
                  {currentIndex + 1} of {request?.loanDocuments?.length}
                </span>
                <img
                  src={chevRight}
                  className="cursor-pointer"
                  alt="Right"
                  onClick={handleNext}
                />
              </div>
            </div>

            <p className="mb-1 text-center">{selectedDoc?.documentTitle}</p>
            <div className="h-[90%] w-full overflow-hidden border border-black p-3 text-center">
              <img
                onClick={() => window.open(selectedDoc?.documentUrl, "_blank")}
                src={selectedDoc?.documentUrl}
                alt={selectedDoc?.documentTitle}
                className="inline-block h-full object-contain hover:cursor-pointer"
              />
            </div>
          </section>

          <section className={cx(infoTab)}>
            <p className="mb-5 font-[500] text-[#31054E]">Customer</p>

            <LoanInfo name="Class" value={"N/A"} />

            <LoanInfo
              name="Wallet Bal."
              value={formatCurrency(request?.walletBalance)}
            />
            <LoanInfo
              name="Sign Up Date"
              value={getDate(request?.signUpDate)}
            />
            <LoanInfo
              name="Avg. Monthly Transaction"
              value={formatCurrency(request?.previousMonthAverageTransaction)}
            />
          </section>
          <section className={cx(infoTab)}>
            <p className="mb-5 font-[500] text-[#31054E]">Loans Transaction</p>

            <div className="overflow-hidden rounded-[4px] border-none text-[14px] font-light text-[#31054E]">
              <div className="flex items-center justify-between gap-3 border-b-[.1px] border-b-[#C7B6CA] bg-[#f3e9ff] px-3 py-4">
                <p className="leading-none">Amount to borrow:</p>
                <p className="font-[600]">
                  {formatCurrency(request?.loanAmount)}
                </p>
              </div>

              <div className="flex items-center justify-between gap-3 border-b-[.1px] border-b-[#C7B6CA] bg-[#f3e9ff] px-3 py-4">
                <p className="leading-none">
                  {request?.interestRate}% Interest (per month)
                </p>
                <p className="font-[600]">
                  {formatCurrency(request?.interestAmount)}
                </p>
              </div>

              <div className="flex items-center justify-between  gap-3 bg-[#E2CDFF] px-3 py-4 font-[600]">
                <p className="leading-none">Total Repayable Amount</p>
                <p>{formatCurrency(request?.totalRepaymentAmount)}</p>
              </div>
            </div>

            <div className="mt-3 overflow-hidden border-none text-[14px]">
              <div className="flex items-center justify-between gap-3 p-3">
                <div onClick={() => setShowRepayments(!showRepayments)}>
                  <img
                    src={showRepayments ? caretDown : caretUp}
                    alt={
                      showRepayments ? "close repayments" : "open repayments"
                    }
                    className="mr-2 inline-block cursor-pointer"
                  />
                  <p className="inline-block cursor-pointer">Repayment date:</p>{" "}
                  <br />
                  <span className="cursor-pointer text-[#99989A]">
                    View repayment schedule
                  </span>
                </div>
                <p className="font-[600]">
                  {request?.endDate == null ? "N/A" : getDate(request?.endDate)}
                </p>
              </div>

              <div className="flex items-start justify-between gap-3 p-3">
                <p>
                  Late payment ({request?.defaultInterestRate}%):
                  <br />
                  <span className="text-[12px] text-[#99989A]">
                    {/* Applicable from {getDate("09/01/2024")} */}
                  </span>
                </p>
                <p className="font-[600]">
                  {formatCurrency(request?.defaultAmount)}
                </p>
              </div>
            </div>
          </section>
          <section className={cx(infoTab)}>
            <p className="mb-5 font-[500] text-[#31054E]">Admin Decision</p>
            {request?.status === LOAN_STATUS_LIST.Pending ? (
              <form onSubmit={approveDeclineLoan}>
                <SelectField
                  boxStyle="mb-[24px]"
                  name="decision"
                  label="Decision"
                  isRequired
                  defaultName="Select decision"
                  defaultValue=""
                  options={[
                    { name: "Approved", value: "Approved" },
                    { name: "Declined", value: "Declined" },
                  ]}
                  value={decision}
                  onChange={(e: any) => setDecision(e?.target?.value)}
                />

                {decision === "Declined" && (
                  <TextField
                    boxStyle="mb-[18px]"
                    name="comment"
                    label="Additional Notes"
                    placeholder="Additional  notes"
                    rows={5}
                    value={comments}
                    onChange={(e: any) => setComments(e?.target?.value)}
                  />
                )}

                <button
                  className={cx(
                    actionbtn,
                    "w-full rounded-[3px] !bg-brand !px-[26px] !py-3 !text-white",
                  )}
                >
                  Submit
                </button>
              </form>
            ) : (
              <>
                <LoanInfo name="Loan Status" value={request?.status!} />

                <LoanInfo
                  name="Is Defaulted"
                  value={request?.isDefaulted ? "True" : "False"}
                />

                <LoanInfo
                  name="Amount Paid"
                  value={formatCurrency(request?.amountPaid)}
                />

                <LoanInfo
                  name="Outstanding Balance"
                  value={formatCurrency(request?.balance)}
                />

                <LoanInfo
                  name={
                    request?.status === LOAN_STATUS_LIST.Active
                      ? "Approved By"
                      : "Declined By"
                  }
                  value={request?.approvedBy!}
                />

                <LoanInfo
                  name="Date Closed"
                  value={
                    request?.dateClosed == null
                      ? "N/A"
                      : getDate(request?.dateClosed)
                  }
                />
              </>
            )}
          </section>
          <section
            className={cx(infoTab, "!col-span-full !p-0 xl:!col-span-9")}
          >
            <Table
              headers={[
                "Pre-Approved Amount",
                "Request Amount",
                "Repaid Amount",
                "Interest Earned",
                "Default",
                "Decision",
              ]}
            >
              {userRequestList?.data?.length > 0 ? (
                userRequestList?.data?.map((loan) => (
                  <tr key={loan?.id}>
                    <Row value={formatCurrency(loan?.eligibleAmount)} />
                    <Row value={formatCurrency(loan?.loanAmount)} />
                    <Row value={formatCurrency(loan?.amountPaid)} />
                    <Row value={formatCurrency(loan?.interestAmount)} />
                    <Row value={loan?.isDefaulted ? "True" : "False"} />
                    <Row value={loan?.status} />
                  </tr>
                ))
              ) : (
                <tr>
                  <Row colSpan={6} value="no loan request yet" />
                </tr>
              )}
            </Table>

            <div className="px-3">
              <Pagination
                pageNumber={userRequestList?.pageNumber}
                pageSize={userRequestList?.pageSize}
                totalCount={userRequestList?.totalCount}
                totalPage={userRequestList?.totalPage}
                onFetch={fetchMore}
              />
            </div>
          </section>
        </section>
      </div>

      {showRepayments && (
        <Modal
          header={`Repayment Schedule`}
          boxStyle="!min-w-[200px]"
          onClose={() => setShowRepayments(false)}
        >
          <div className="mb-3">
            <p className="mb-3 mt-2 font-[500] text-[#31054E]">
              Repayment Schedule
            </p>

            <div className="overflow-hidden rounded-[4px] border-none">
              <div className="text-purple-dark flex items-center justify-between gap-3 border-b-[.1px] border-b-[#C7B6CA] bg-[#E2CDFF] px-3 py-4">
                <p>Number of installments</p>
                <p className="font-[600]">{request?.tenorInMonths}</p>
              </div>

              {request?.loanRepayments?.map((repayment, index: any) => (
                <div
                  key={index}
                  className="text-purple-dark flex items-center justify-between gap-3 border-b-[.1px] border-b-[#C7B6CA] bg-[#f3e9ff] px-3 py-4"
                >
                  <p>Payment {index + 1}</p>
                  <p>{getDate(repayment?.dueDate)}</p>
                  <p className="font-[600]">
                    {formatCurrency(repayment?.repaymentAmount)}
                  </p>
                  <p
                    className={cx(
                      "!px-[20px] !py-[2px] text-[14px]",
                      getLoanStatusColor(repayment?.status),
                    )}
                  >
                    <span>{repayment?.status}</span> <br />
                    {repayment?.status?.toLocaleLowerCase() ==
                      REPAYMENT_STATUS.Pending &&
                      repayment?.amountPaid > 0 && (
                        <span className="text-[12px]">
                          (
                          {formatCurrency(
                            repayment?.repaymentAmount - repayment?.amountPaid,
                          )}
                          )
                        </span>
                      )}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 flex gap-3">
            <button
              type="button"
              onClick={() => setShowRepayments(false)}
              className={`${actionbtn} w-full bg-[#F2EAF8] !px-[26px] !py-3 `}
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LoanDetails;
