import Pagination from "core/components/Pagination";
import DatePicker from "core/components/formfields/DatePicker";
import SelectField from "core/components/formfields/SelectField";
import { searchImg, viewOrderImg } from "core/consts/images";
import { actionbtn } from "core/consts/styling";
import {
  MARKETPLACE_CATEGORY,
  MARKETPLACE_CATEGORY_TYPES,
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  PROCESS_ORDER,
} from "core/consts/systemConst";
import {
  formatCurrency,
  getDateTime,
  getStatusBackgroundColor,
} from "core/helpers/generalHelpers";
import useLocationStore from "core/services/stores/useLocationStore";
import useMarketPlaceStore from "core/services/stores/useMarketPlaceStore";
import useUserStore from "core/services/stores/useUserStore";
import CancelReasonForm from "modules/partials/CancelReasonForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ViewOrders = () => {
  const locations = useLocationStore((store) => store.locations);
  const navigate = useNavigate();
  const getLocationAction = useLocationStore((store) => store.getLocations);

  const isSuperAdmin = useUserStore((store) => store.isSuperAdmin);
  const location = useUserStore((store) => store.location);

  const orderList = useMarketPlaceStore((store) => store.orderList);
  const getOrderAction = useMarketPlaceStore((store) => store.getOrders);
  const getOrderStatusAction = useMarketPlaceStore(
    (store) => store.getOrderStatus,
  );
  const updateOrderStatusAction = useMarketPlaceStore(
    (store) => store.updateOrderStatus,
  );

  const [query, setQuery] = useState<ProductQuery>({
    category: MARKETPLACE_CATEGORY_TYPES.Fast_Food?.valueOf(),
    endDate: "",
    startDate: "",
    userId: "",
    locationFilter: location,
    searchText: "",
    orderStatus: "",
  });

  const [reason] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [openReasonModal, setOpenReasonModal] = useState(false);

  const onQueryChange = async (e: any, isAuto: boolean = true) => {
    const { name, value } = e?.target;

    setQuery((state) => ({
      ...state,
      [name]: value,
    }));

    if (isAuto) {
      await getOrderAction(
        { ...query, [name]: value },
        orderList?.pageNumber,
        orderList?.pageSize,
      );
    }
  };

  const fetchMore = async (page: number) => {
    await getOrderAction(query, page, orderList?.pageSize);
  };

  const updateOrder = async (order: ClotheOrder, status: ManageOrder) => {
    var msg = "";

    switch (status) {
      case PROCESS_ORDER.accept:
        msg = `Click 'OK' to accept the order from ${order?.customerName}.`;
        break;
      case PROCESS_ORDER.cancel:
        msg = `Click 'OK' to confirm cancelling the order from ${order?.customerName}.`;
        break;
      case PROCESS_ORDER.delivered:
        msg = `Click 'OK' to confirm marking the order from ${order?.customerName} as delivered.`;
        break;
      default:
        break;
    }

    if (window.confirm(msg)) {
      var res = await updateOrderStatusAction(order?.orderId, status, reason);

      if (res?.isSuccessful) {
        setOpenReasonModal(false);
        setSelectedOrder(null);
      }
    }
  };

  const getStatus = async (id: string) => {
    var res = await getOrderStatusAction(id);
    if (res?.isSuccessful)
      getOrderAction(query, orderList?.pageNumber, orderList?.pageSize);
  };

  useEffect(() => {
    getOrderAction(query, orderList?.pageNumber, orderList?.pageSize);
    locations?.length < 1 && getLocationAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mx-auto mb-[28px] w-[95%]">
        <div className="mb-[24px] flex items-center justify-between gap-5">
          <div className="w-8/12">
            <header className="items- mb-[18px] flex items-center justify-between gap-3">
              <div className="flex items-center gap-3">
                <img src={viewOrderImg} alt="active module" loading="lazy" />
                <h5 className="text-[18px] font-[500] text-brand">
                  View Orders
                </h5>
              </div>

              <div className="w-6/12">
                <SelectField
                  selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
                  name="locationFilter"
                  defaultName="Select Location"
                  defaultValue=""
                  disabled={!isSuperAdmin}
                  options={
                    locations?.length > 0
                      ? [
                          ...locations?.map((location) => ({
                            name: location?.name,
                            value: location?.name,
                          })),
                        ]
                      : []
                  }
                  value={query?.locationFilter}
                  onChange={(e: any) => {
                    onQueryChange(e);
                  }}
                />
              </div>
            </header>

            <div className="flex items-center justify-between gap-5">
              <div className="flex h-12 w-2/3 items-center justify-between gap-3 rounded-l-[25px] rounded-r-[5px] border-[1px] border-brand bg-white px-2 shadow-md">
                <input
                  type="text"
                  name="searchText"
                  placeholder="Search"
                  value={query?.searchText}
                  onChange={(e: any) => onQueryChange(e, false)}
                  className="w-10/12 bg-transparent px-3 py-1 text-[14px] text-brand outline-none disabled:cursor-not-allowed"
                />
                <button
                  type="button"
                  onClick={() => {
                    getOrderAction(
                      query,
                      orderList?.pageNumber,
                      orderList?.pageSize,
                    );
                  }}
                  className="px-3 py-1 text-black disabled:cursor-not-allowed"
                >
                  <img
                    src={searchImg}
                    alt="search"
                    className="h-[25px] w-[25px]"
                  />
                </button>
              </div>

              <div className="w-1/3">
                <SelectField
                  selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
                  name="orderStatus"
                  defaultName="Filter by Order Status"
                  defaultValue=""
                  options={ORDER_STATUS_LIST}
                  value={query?.orderStatus}
                  onChange={(e: any) => {
                    onQueryChange(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-4/12">
            <div className="mb-[18px] grid grid-cols-2 gap-5">
              <DatePicker
                name="startDate"
                label="Start Date"
                value={query?.startDate}
                className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
                onChange={(e: any) => {
                  onQueryChange(e);
                }}
              />

              <DatePicker
                name="endDate"
                label="End Date"
                value={query?.endDate}
                className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
                onChange={(e: any) => {
                  onQueryChange(e);
                }}
              />
            </div>
            <SelectField
              selectStyle="!border-brand !shadow !text-[14px] !bg-brandLight !text-brand"
              name="category"
              defaultName=""
              defaultValue=""
              options={
                MARKETPLACE_CATEGORY?.length > 0
                  ? [
                      ...MARKETPLACE_CATEGORY?.map((cat) => ({
                        name: cat?.name,
                        value: cat?.value,
                      })),
                    ]
                  : []
              }
              value={query?.category}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />
          </div>
        </div>

        <main className="my-[24px]">
          {
            <>
              {orderList?.orders?.length > 0 ? (
                <>
                  {orderList?.orders.map((order: any) => (
                    <div
                      key={order?.orderId}
                      className={`mb-[24px] flex w-full items-center justify-between gap-5 overflow-x-scroll rounded-[5px] border-[1px] border-brand bg-white  px-4 py-3 text-[#8E8787] shadow-md
                          ${
                            order?.successStatus
                              ? getStatusBackgroundColor(order?.status)
                              : getStatusBackgroundColor(order?.status)
                          }
                          `}
                    >
                      <div
                        className="hover:cursor-pointer md:w-1/3"
                        onClick={() => {
                          navigate(
                            `/marketplace/view-orders/${query?.category}/${order?.orderId}`,
                          );
                        }}
                      >
                        <p
                          className={`mb-[5px] font-bold capitalize ${
                            order?.status === ORDER_STATUS?.pending &&
                            "!text-brand"
                          }`}
                        >
                          {order?.customerName}
                        </p>
                        <p className="text-[14px] font-[500] capitalize">
                          {order.productName &&
                            `Product Name: ${order.productName}`}
                          {order.serviceType &&
                            `Service Type: ${order.serviceType}`}
                        </p>
                        <p className="text-[14px] font-[500] capitalize">
                          Order Date: {getDateTime(order?.createdAt)}
                        </p>
                      </div>

                      <div
                        className="w-1/3 text-[14px] capitalize hover:cursor-pointer"
                        onClick={() => {
                          navigate(
                            `/marketplace/view-orders/${query?.category}/${order?.orderId}`,
                          );
                        }}
                      >
                        <p>Receipt No: {order?.receiptNumber}</p>
                        <p>
                          Total Amount: {formatCurrency(order?.totalAmount)}
                        </p>
                        <p>Status: {order?.status}</p>
                      </div>

                      <div className="flex w-1/3 gap-2">
                        {order?.successStatus &&
                          ![
                            ORDER_STATUS.cancelled?.toLowerCase(),
                            ORDER_STATUS.delivered?.toLowerCase(),
                          ].includes(order?.status?.toLowerCase()) && (
                            <>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-brand-200`}
                                onClick={() => updateOrder(order, "accept")}
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    ORDER_STATUS?.processing,
                                    ORDER_STATUS?.delivered,
                                    ORDER_STATUS?.cancelled,
                                  ].includes(order?.status)
                                }
                              >
                                Accept
                              </button>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-[#DFA1F4]`}
                                onClick={() => {
                                  setSelectedOrder(order);
                                  setOpenReasonModal(true);
                                }}
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    ORDER_STATUS?.delivered,
                                    ORDER_STATUS?.cancelled,
                                  ].includes(order?.status)
                                }
                              >
                                Cancel
                              </button>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                                onClick={() => updateOrder(order, "delivered")}
                                disabled={
                                  isSuperAdmin ||
                                  [
                                    ORDER_STATUS?.pending,
                                    ORDER_STATUS?.delivered,
                                    ORDER_STATUS?.cancelled,
                                  ].includes(order?.status)
                                }
                              >
                                Marked Delivered
                              </button>
                            </>
                          )}

                        {!order?.successStatus &&
                          order?.status === ORDER_STATUS?.awaiting_payment && (
                            <>
                              <button
                                className={`${actionbtn} !border-[#6C18A4] !bg-[#6C18A4] !text-white`}
                                onClick={() => {
                                  getStatus(order?.orderId);
                                }}
                              >
                                Check Payment Status
                              </button>
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="mb-[24px] flex items-center justify-between gap-5 rounded-[5px] border-[1px] border-brand bg-white px-4 py-3 shadow-md">
                  <div>
                    <p className="mb-[5px] font-[500] text-[#2E2626]">
                      No order yet
                    </p>
                  </div>
                </div>
              )}
            </>
          }
        </main>

        <Pagination
          pageNumber={orderList?.pageNumber}
          pageSize={orderList?.pageSize}
          totalCount={orderList?.totalCount}
          totalPage={orderList?.totalPage}
          onFetch={fetchMore}
        />
      </section>

      <CancelReasonForm
        show={openReasonModal}
        onShow={setOpenReasonModal}
        order={selectedOrder}
        onCancel={updateOrder}
      />
    </>
  );
};

export default ViewOrders;
