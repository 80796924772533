export const generateEmailTemplate = ({
  title,
  sections,
  isServer = false,
}: {
  title: string;
  sections: DraggableSection[];
  isServer?: boolean;
}) => {
  var contents = "";

  sections?.length > 0 &&
    sections.forEach((section) => {
      if (section.type === "text") {
        contents += `<p>${section?.content}</p>`;
      } else if (section.type === "image" && section.imageFile) {
        contents += `<img src="${section?.content}" alt="${title}" />`;
      } else if (section.type === "header") {
        contents += `<h2>${section.content}</h2>`;
      }
    });

  return `
    <!doctype html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="x-apple-disable-message-reformatting" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>Campusrunz.com - ${title}</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap"
          rel="stylesheet"
        />
        <style>
          /* General Styles */
          body {
            margin: 0;
            padding: 0;
            font-family: "Nunito", Arial, sans-serif;
            background-color: #f4f7fc;
            color: #333333;
            font-size: 16px;
            -webkit-font-smoothing: antialiased;
          }
  
          table {
            border-spacing: 0;
            border-collapse: collapse;
            width: 100%;
            margin: 0 auto;
            max-width: 600px;
          }
  
          img {
            border: 0;
            line-height: 100%;
            vertical-align: middle;
            max-width: 100%;
          }
  
          a {
            color: #462884;
            text-decoration: none;
          }
  
          /* Container Styles */
          .email-container {
            width: 100%;
            max-width: 600px;
            margin: 40px auto;
            background-color: #ffffff;
            border-radius: 2px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            overflow: hidden;
          }
  
          /* Content Section */
          .content {
            padding: 20px 30px 40px;
            color: #333333;
            line-height: 1.5;
          }
  
          .content h2 {
            font-size: 18px;
            color: #462884;
            margin-bottom: 10px;
          }
  
          .content img {
            margin-bottom: 15px;
            border-radius: 2px;
          }
  
          .content p {
            margin-bottom: 15px;
          }
  
          /* Details Section */
          .details {
            background-color: #f9f9f9;
            padding: 15px;
            margin: 20px 0;
            border-radius: 5px;
            border: 1px solid #d6d6d6;
          }
  
          .details div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
  
          .details p {
            margin: 0;
            font-weight: bold;
          }
  
          .details span {
            color: #462884;
          }
  
          /* Button Section */
          .button-container {
            text-align: center;
            margin: 30px 0;
          }
  
          .button {
            display: inline-block;
            background-color: #462884;
            color: #ffffff;
            text-decoration: none;
            padding: 12px 30px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: bold;
          }
  
          .button:hover {
            background-color: #361d6c;
          }
  
          /* Footer Section */
          .footer {
            text-align: center;
            font-size: 14px;
            color: #818181;
            padding: 20px 10px;
          }
  
          .footer span {
            color: #03a9f5;
            font-weight: bold;
          }
  
          /* Media Query for Small Screens */
          @media only screen and (max-width: 600px) {
            .content {
              padding: 15px;
            }
  
            .button {
              padding: 10px 20px;
              font-size: 14px;
            }
  
            .details div {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        </style>
      </head>
      <body>
        <!-- Main Email Container -->
        <table role="presentation" class="email-container">
          <!-- Header Section -->
          <tr>
          ${
            isServer
              ? `
            <td class="content" style="text-align: right">
              <img
            src="https://1drv.ms/i/c/b8295c03d418d49b/IQSccMl5tVadQ7gqwSMK7qDoAV6PkMKE1X0Aq3Tpa3QmGFY?width=1024"
            style="width: 40px; height: 40px"
          />
          <img
            src="https://1drv.ms/i/c/b8295c03d418d49b/IQSVfbJvfj6YRa8paisiQu3BAeIDJ4Q_TWTtNFJSDuhlW-g?width=1024"
            style="height: 20px; width: auto"
          />
            </td>
          </tr>`
              : ""
          }
  
          <!-- Main Content Section -->
          <tr>
            <td class="content">
             ${contents}
            </td>
          </tr>
  
          <!-- Footer Section -->
          <tr>
            <td style="padding: 20px; background: #7037c5; color: #ffffff">
              <table
                role="presentation"
                style="
                  width: 100%;
                  border-collapse: collapse;
                  border: 0;
                  border-spacing: 0;
                "
              >
                <tr>
                  <td
                    style="
                      width: 20%;
                      padding: 0;
                      vertical-align: top;
                      text-align: right;
                    "
                  >
                    <a href="mailto:hello@campusrunz.com.ng">
                    <img
                    src="https://1drv.ms/i/c/b8295c03d418d49b/IQSq5klFLLQfR6EOyz4b3Da3AUMiaU5Zph0IabYAwCykGIQ"
                    width="100"
                    height="100"
                     /> 
                    </a>
                  </td>
  
                  <td style="width: 3%"></td>
  
                  <td
                    style="
                      width: 70%;
                      padding: 0;
                      vertical-align: top;
                      color: #ffffff;
                    "
                  >
                    <span
                      style="
                        font-weight: bold;
                        font-family: Arial, Helvetica, sans-serif;
                      "
                      >Need any help or have questions?</span
                    >
                    <br />
                    <span>
                      Just click on the icon to reply to this email. We're always
                      happy to help you out.
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <!-- Margin Between Rows -->
          <tr>
            <td
              style="line-height: 10px; height: 10px; mso-line-height-rule: exactly"
            >
              &nbsp;
            </td>
          </tr>
  
          <!-- Email Signature -->
          <tr align="center">
            <td>
              <div style="width: 60px; height: 60px; margin: 20px 0 0;">
                <img
                  src="https://1drv.ms/i/c/b8295c03d418d49b/IQS31R3gcTjaQqwUpjDmRZUAAaVUI5q-QyJfgVc_n5YS7-A"
                  width="60"
                  height="60"
                />
              </div>
              <div style="color: #5f5f5f">
                <span
                  style="
                    font-weight: bold;
                    font-family: Arial, Helvetica, sans-serif;
                  "
                  >Cheers</span
                >
                <p style="margin-top: 5px">CampusRunz Team</p>
              </div>
            </td>
          </tr
        </table>
      </body>
    </html>
    `;
};
