import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import notification from "core/helpers/notification";
import {
  addUpdateClothe,
  deleteClothe,
  deleteClotheImage,
  getClotheById,
  getClothes,
  getLaundryDashboard,
  getLaundryOrderById,
  getLaundryOrders,
  toggleClothe,
} from "../api/laundry.api";
import { updateOrderStatus } from "../api/userapi";
import { getProcessStatus } from "core/helpers/generalHelpers";

type StoreState = {
  isLoading: boolean;
  analytics: LaundryAnalytics;
  clotheList: ClotheList;
  clothe: any;
  order: any;
  orderList: {
    orders: ClotheOrder[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
  };
  getDashboardAnalytics: (query: DateLocationQuery) => Promise<void>;
  getClothes: (
    query: LaundryQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  getClotheById: (clotheId: string) => Promise<void>;
  addUpdateClothe: (clothe: NewClothe, clotheId: string) => Promise<UIResponse>;
  toggleClothe: (clotheId: string, visibility: boolean) => Promise<void>;
  deleteClothe: (clotheId: string) => Promise<void>;
  deleteClotheImage: (
    category: string,
    clotheId: string,
    image: string,
  ) => Promise<void>;
  getOrders: (
    query: LaundryQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  updateOrderStatus: (
    orderId: string,
    orderStatus: ManageOrder,
    reason: string,
  ) => Promise<UIResponse>;
  getOrderById: (orderId: string) => Promise<void>;
  resetOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  analytics: {
    listedClothes: 0,
    totalOrders: 0,
    totalQuantity: 0,
    totalServiceIncome: 0,
    totalConvenienceFee: 0,
    totalIncome: 0,
  },
  clotheList: {
    clothes: [],
    pageNumber: 0,
    pageSize: 15,
    totalCount: 0,
    totalPage: 0,
  },
  orderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  clothe: null,
  order: null,
};

const useLaundryStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        getDashboardAnalytics: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getLaundryDashboard(query);
          if (res?.isSuccessful) {
            set({
              analytics: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getOrders: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getLaundryOrders(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              orderList: {
                orders: res?.data?.data?.orders,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getOrderById: async (orderId) => {
          set({ isLoading: true });

          var res: UIResponse = await getLaundryOrderById(orderId);

          if (res?.isSuccessful) {
            set({
              order: res?.data?.data,
            });
          }

          set({ isLoading: false });
        },
        getClothes: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getClothes(query, pageNumber, pageSize);

          if (res?.isSuccessful) {
            set({
              clotheList: {
                clothes: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getClotheById: async (clotheId) => {
          set({ isLoading: true });

          var res: UIResponse = await getClotheById(clotheId);
          if (res?.isSuccessful) {
            set({
              clothe: res?.data?.data,
              isLoading: false,
            });
          } else {
            set({ clothe: null, isLoading: false });
          }
        },
        addUpdateClothe: async (clothe, clotheId) => {
          set({ isLoading: true });

          var res: UIResponse = await addUpdateClothe(clothe, clotheId);

          if (res?.isSuccessful && clotheId?.length > 1) {
            get().getClotheById(clotheId);
          } else {
            set({ isLoading: false });
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          return res;
        },
        deleteClotheImage: async (category, clotheId, image) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteClotheImage(
            category,
            clotheId,
            image,
          );

          if (res?.isSuccessful) {
            get().getClotheById(clotheId);
          }

          notification({
            message: res?.isSuccessful
              ? "Image has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });
        },
        deleteClothe: async (clotheId) => {
          set({ isLoading: true });

          var res: UIResponse = await deleteClothe(clotheId);

          if (res?.isSuccessful) {
            set((state) => ({
              clotheList: {
                ...state.clotheList,
                clothes: state?.clotheList?.clothes?.filter(
                  (clothe) => clothe?.clotheId !== clotheId,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? "Clothe has been deleted successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        toggleClothe: async (clotheId, visibility) => {
          set({ isLoading: true });

          var res: UIResponse = await toggleClothe(clotheId, visibility);

          if (res?.isSuccessful) {
            set((state) => ({
              clotheList: {
                ...state.clotheList,
                clothes: state?.clotheList.clothes?.map((clothe) =>
                  clothe?.clotheId === clotheId
                    ? { ...clothe, visibility: visibility }
                    : clothe,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
        },
        updateOrderStatus: async (orderId, orderStatus, reason) => {
          set({ isLoading: true });

          var res: UIResponse = await updateOrderStatus(
            orderId,
            orderStatus,
            reason,
          );

          var processStatus = "";

          if (res?.isSuccessful) {
            processStatus = getProcessStatus(orderStatus);

            set((state) => ({
              orderList: {
                ...state.orderList,
                orders: state?.orderList?.orders?.map((order: ClotheOrder) =>
                  order?.orderId === orderId
                    ? { ...order, status: processStatus }
                    : order,
                ),
              },
            }));
          }

          notification({
            message: res?.isSuccessful
              ? `Order status has been updated successfully to ${processStatus}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        resetOrder: () => {
          set({ order: null });
        },
        reset: () => {
          set({ ...defaultState });
        },
      }),
      {
        name: "laundryStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useLaundryStore;
