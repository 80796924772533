import SelectBox from "core/components/formfields/SelectField";
import { downloadImg } from "core/consts/images";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "core/components/formfields/DatePicker";
import { EVENTS } from "core/consts/systemConst";
import notification from "core/helpers/notification";
import { cx, formatNumber } from "core/helpers/generalHelpers";
import android from "assets/icons/android.svg";
import web from "assets/icons/web.svg";
import apple from "assets/icons/apple.svg";
import unknown from "assets/icons/unknown.svg";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import LineChart from "core/components/charts/LineChart";
import PushNotification from "./partials/PushNotification";
import EmailEngagement from "./partials/EmailEngagement";
import SmsEngagement from "./partials/SmsEngagement";
import MultiSelectField from "core/components/formfields/MultiSelectField";

const EngagementAnalytics = () => {
  const locations = useLocationStore((store) => store.locations);
  const getLocationAction = useLocationStore((store) => store.getLocations);
  const analyticsQuery = useAnalyticStore((store) => store.analyticsQuery);
  const [query, setQuery] = useState<any>({ ...analyticsQuery });

  const eventType = query?.event === 0 ? "Sign ups" : "Sign ins";

  const [engagementType, setEngagementType] = useState(1);

  const analytics = useAnalyticStore((store) => store.eventGraphData);
  const estimates = useAnalyticStore(
    (store) => store.eventAnalytics.estimatedTargetUsers,
  );

  const memoizedEstimates = useMemo(() => {
    if (!estimates || estimates.length === 0) return null;

    return estimates.reverse().map((es) => {
      const getImageSrc = (deviceType: string) => {
        switch (deviceType) {
          case "IOS":
            return apple;
          case "Android":
            return android;
          case "Web":
            return web;
          case "UnIdentified":
            return unknown;
          default:
            return unknown;
        }
      };

      return (
        <div key={es?.deviceTypeId} className="border-r px-5 py-5">
          <img
            className="mr-3 inline-block h-[24px] w-auto"
            src={getImageSrc(es?.deviceType)}
            alt={es?.deviceType}
          />
          <p className="inline font-light text-[#878787]">
            {formatNumber(es?.count)}
          </p>
        </div>
      );
    });
  }, [estimates]); // Only recompute when `estimates` changes

  const memoizedSeries = useMemo(
    () => [
      {
        name: "No of Female",
        data: [...analytics.female],
      },
      {
        name: "No of Male",
        data: [...analytics.male],
      },
    ],
    [analytics],
  );

  const memoizedCategories = useMemo(() => [...analytics.date], [analytics]);

  const totalEstimates =
    estimates?.reduce((total, user) => total + user.count, 0) || 0;

  const getAnalyticsAction = useAnalyticStore(
    (store) => store.getEventAnalytics,
  );

  const exportData = async () => {
    notification({
      message: "Feature not available at the moment",
      type: "warning",
    });
  };

  const onQueryChange = (e: any) => {
    const { name, value } = e?.target;

    setQuery((state: any) => ({
      ...state,
      [name]: value,
    }));
  };

  const search = async (e: any) => {
    e.preventDefault();
    await getAnalyticsAction({ ...query });
  };

  useEffect(() => {
    locations?.length < 1 && getLocationAction();
    getAnalyticsAction({ ...query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="mx-auto w-[95%]">
      <header className="mb-3 flex flex-col justify-start lg:flex-row lg:justify-between">
        <div className="flex flex-col items-start gap-3 font-[500] text-brand lg:flex-row">
          <h5 className="text-[18px] font-[500] text-brand">Analytics</h5>
        </div>
      </header>

      <div className="mb-[24px] flex flex-col-reverse gap-5 lg:flex-row">
        <div className="w-full lg:w-8/12">
          <section>
            <div className="mb-20 rounded-sm border border-t-2 border-brand bg-white p-3 shadow-sm">
              <p className="mb-5 text-xs">No of {eventType}</p>
              <LineChart
                id="events"
                series={memoizedSeries}
                colors={["#6c18a4", "#dfa1f4"]}
                categories={memoizedCategories}
                xLabel=""
                yAxis={[
                  {
                    title: {
                      style: {
                        color: "#6C18A4",
                      },
                      text: `No of ${eventType}`,
                    },
                  },
                ]}
              />
            </div>

            <div className="mb-3 flex flex-col items-start justify-between gap-3 lg:flex-row">
              <h5 className="text-base font-medium text-brand">
                Estimated Target Users
              </h5>
              <p className="text-sm font-light text-[#878787]">
                {formatNumber(totalEstimates)} Users
              </p>
            </div>

            <div className="mb-20 flex items-center gap-3 rounded-sm border border-t-2 border-brand bg-white shadow-sm">
              {memoizedEstimates}
            </div>

            <div className="mb-3 flex flex-col items-start justify-between gap-3 lg:flex-row">
              <h5 className="text-base font-medium text-brand">
                Engagement center
              </h5>

              <p className="text-sm font-light text-[#878787]">
                {(() => {
                  switch (engagementType) {
                    case 1:
                      return `${formatNumber(totalEstimates)} Devices`;
                    case 2:
                      return `${formatNumber(totalEstimates)} Phone numbers`;
                    case 3:
                      return `${formatNumber(
                        totalEstimates,
                      )} Targeted email addresses`;
                    default:
                      return `${formatNumber(totalEstimates)} Users`;
                  }
                })()}
              </p>
            </div>

            <div className="rounded-sm border border-t-2 border-brand bg-white p-5 shadow-sm">
              <header className="flex justify-start gap-2">
                <p
                  onClick={(e) => {
                    setEngagementType(1);
                    e.stopPropagation();
                  }}
                  className={cx("tabs", engagementType === 1 ? "active" : "")}
                >
                  Push Notifications
                </p>
                <p
                  onClick={() => setEngagementType(2)}
                  className={cx(
                    "tabs border-x border-x-black",
                    engagementType === 2 ? "active" : "",
                  )}
                >
                  Short Messaging (SMS)
                </p>
                <p
                  onClick={() => setEngagementType(3)}
                  className={cx("tabs", engagementType === 3 ? "active" : "")}
                >
                  Emails
                </p>
              </header>

              {(() => {
                switch (engagementType) {
                  case 1:
                    return <PushNotification />;
                  case 2:
                    return <SmsEngagement />;
                  case 3:
                    return <EmailEngagement />;
                  default:
                    return <></>;
                }
              })()}
            </div>
          </section>
        </div>
        <div className="w-full lg:w-4/12">
          <MultiSelectField
            multiple
            boxStyle="mb-[18px]"
            label="Select your Location"
            selectStyle="!border-brand text-[#615353]"
            name="locationId"
            value={query?.locationId}
            options={
              locations?.length > 0
                ? [
                    ...locations?.map((location) => ({
                      label: location.name,
                      value: location.id,
                    })),
                  ]
                : []
            }
            onChange={(e: any) => {
              setQuery((state: any) => ({
                ...state,
                locationId: e,
              }));
            }}
          />

          <SelectBox
            boxStyle="mb-[18px]"
            selectStyle="!border-brand !bg-brand text-white"
            name="event"
            options={EVENTS?.length > 0 ? [...EVENTS] : []}
            value={query?.event}
            onChange={(e: any) => onQueryChange(e)}
          />

          <div className="mb-[18px] grid grid-cols-2 gap-5 lg:grid-cols-2">
            <DatePicker
              name="startDate"
              label="Start Date"
              value={query?.startDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => onQueryChange(e)}
            />
            <DatePicker
              name="endDate"
              label="End Date"
              value={query?.endDate}
              className={`flex h-12 w-full items-center justify-between rounded-[5px] border-[1px] border-brand bg-brand p-3 text-sm text-white outline-none`}
              onChange={(e: any) => {
                onQueryChange(e);
              }}
            />
          </div>

          <button
            className="mb-[18px] flex w-full items-center justify-center rounded-[5px] border-brand bg-brand-400 p-3 text-brand disabled:cursor-not-allowed"
            onClick={(e) => search(e)}
          >
            <span className="text-[14px]">Search</span>
          </button>

          <button
            disabled
            className="flex w-full items-center justify-between rounded-[5px] bg-brand p-3 text-white disabled:cursor-not-allowed"
            onClick={() => exportData()}
          >
            <span className="text-[14px]">Download</span>
            <img src={downloadImg} loading="lazy" alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default EngagementAnalytics;
