import InputField from "core/components/formfields/InputField";
import TextField from "core/components/formfields/TextField";
import { btn } from "core/consts/styling";
import { cx } from "core/helpers/generalHelpers";
import notification from "core/helpers/notification";
import useAnalyticStore from "core/services/stores/useAnalyticStore";
import React, { useState } from "react";

export default function PushNotification() {
  const query = useAnalyticStore((store) => store.analyticsQuery);
  const sendNotification = useAnalyticStore(
    (store) => store.sendFirebaseNotification,
  );
  const estimates = useAnalyticStore(
    (store) => store.eventAnalytics.estimatedTargetUsers,
  );
  const totalEstimates =
    estimates?.reduce((total, user) => total + user.count, 0) || 0;

  const [saveNotification, setSaveNotification] = useState("false");
  const [data, setData] = useState<EventMessage>({
    messageBody: "",
    messageTitle: "",
  });
  const [errors, setErrors] = useState({
    MessageBody: [],
    MessageTitle: [],
  });

  const onErrorChange = (name: string, value: string[]) => {
    setErrors((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onFormChange = (e: any) => {
    const { name, value } = e?.target;
    setData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const validation = (data: EventMessage) => {
    var isValid = true;

    if (data?.messageTitle?.length < 1) {
      isValid = false;
      onErrorChange("MessageTitle", ["Message Title is required"]);
    }

    if (data?.messageBody?.length < 1) {
      isValid = false;
      onErrorChange("MessageBody", ["Message Body is required"]);
    }

    return isValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (totalEstimates < 1) {
      notification({
        type: "warning",
        message:
          "Estimated audience is currently 0. Please change filter and try again",
      });
      return;
    }

    if (validation(data)) {
      var res = await sendNotification(
        {
          analyticQuery: query,
          messageBody: data?.messageBody,
          messageTitle: data?.messageTitle,
        },
        saveNotification === "true",
      );

      if (res?.isSuccessful) {
        setData({
          messageBody: "",
          messageTitle: "",
        });
        setSaveNotification("false");
      } else {
        setErrors((state) => ({
          ...state,
          ...res?.data,
        }));
      }
    } else {
      notification({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  return (
    <div className="mt-5">
      <form onSubmit={onSubmit}>
        <InputField
          boxStyle="mb-[18px]"
          placeholder="Title of message"
          label="Message Title"
          name="messageTitle"
          value={data?.messageTitle}
          onChange={(e: any) => onFormChange(e)}
          error={errors?.MessageTitle[0]}
          onBlur={() => onErrorChange("MessageTitle", [])}
        />

        <TextField
          boxStyle="mb-[18px]"
          label="Message Body"
          placeholder="Your message"
          name="messageBody"
          value={data?.messageBody}
          onChange={(e: any) => onFormChange(e)}
          rows={8}
          error={errors?.MessageBody[0]}
          onBlur={() => onErrorChange("MessageBody", [])}
        />

        <fieldset className="mb-[18px] flex items-center justify-start gap-2">
          <input
            type="checkbox"
            readOnly
            className="!accent--brand h-[22px] w-[22px] focus:accent-brand"
            checked={saveNotification === "true"}
            onChange={(e) => {
              setSaveNotification(`${e?.target?.checked}`);
            }}
          />
          <p className="text-[14px] text-brandgray">Save Notification</p>
        </fieldset>

        <button className={cx(btn, "w-full bg-brand text-white")}>
          Send Pushes
        </button>
      </form>
    </div>
  );
}
